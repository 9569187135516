import Icon, {
    IconProps
} from '@ews/react-icons';

import
{
    SystemStatus
} from '@ews/react-data';

import
{
    FC
} from 'react';

import
{
    Responsive
} from '../Responsive';

export enum StatusColor
{
    ONLINE = "#5aa700",
    /* 
    OFFLINE = "#e6001c",
    UNKNOWN = "#aeaeae"
     */
    UNKNOWN = "#efc100",
    OFFLINE = "#aeaeae"
};

type StatusIconProps = Omit<IconProps, 'icon'> & {
    status?: SystemStatus;
};

const NetworkStatusIcon: FC<StatusIconProps> = ({
    status = SystemStatus.UNKNOWN,
    style = {},
    ...iconProps
}) =>
{
    style = { ...style, ...{ "--fill-override": StatusColor[SystemStatus[status] as keyof typeof StatusColor] } as React.CSSProperties };

    return (<>
        <Responsive mobile><Icon {...iconProps} icon="smartphone" style={style} /></Responsive>
        <Responsive desktop><Icon {...iconProps} icon="notebook" style={style} /></Responsive>
    </>);

};

const SystemStatusIcon: FC<StatusIconProps> = ({
    status = SystemStatus.UNKNOWN,
    style = {},
    ...iconProps
}) =>
{
    style = { ...style, ...{ "--fill-override": StatusColor[SystemStatus[status] as keyof typeof StatusColor] } as React.CSSProperties };

    return (<span title={SystemStatus[status]} style={{ display: "contents" }}>
        <Icon {...iconProps} icon="bmz_body" style={style} />
    </span>);

};

export
{
    NetworkStatusIcon,
    SystemStatusIcon
};
