import
{
    NotificationPlugin
} from "@ews/push-notifications-plugin";

import
{
    authContext,
    //defaultProvider,
    persistence
} from '@ews/react-auth-context';

import
{
    FC,
    PropsWithChildren,
    useState
} from 'react';

import
{
    ProviderInterface,
    useWebsocket
} from '../ReactData/ReactDataProvider';

import StateChangeListener = authContext.StateChangeListener;

import
    {
        AuthRequest
    } from '@ews/websocket-service';

import
    {
        getDateFormat
    } from '@ews/react-data';

import
    {
        useUser,
        useUserDetails,
        useUserPermissions
    } from '../ReactData/user';

type State = {
    isAuthenticated?: boolean,
    accessToken?: string;
};

type Persistence = {
    store(data: State): void;
    retrieve(): State;
};

let isAuthPending = false;

const websocketProvider = (
    channel: ProviderInterface,
    persistence?: Persistence
) =>
{

    let stateChange: StateChangeListener;
    let state: State = { ...(persistence ? persistence.retrieve() : {}), isAuthenticated: undefined };

    const isAuthenticated = () =>
    {
        return state.isAuthenticated;
    };

    const onStateChange = async (listener: StateChangeListener) =>
    {
        stateChange = listener;
    };

    const setState = (newState: State) =>
    {
        state = newState;
        if (persistence) persistence.store(state);
        if (stateChange) stateChange(state.isAuthenticated);

    };

    channel.removeAllListeners("auth:response");

    channel.on("auth:response", async (response: AuthRequest) =>
    {
        switch (response.type) {

            case 'auth':
                if (state.accessToken) {
                    try {

                        if (!isAuthPending) {
                            isAuthPending = true;
                            await channel.auth({ type: "token", token: state.accessToken! }) as { type: 'token', token: string; };
                        }



                    } catch (e) {
                        setState({ isAuthenticated: false });
                    }
                } else {
                    setState({ isAuthenticated: false });
                }
                break;

            case 'token':

                isAuthPending = false;
                setState({ accessToken: response.token, isAuthenticated: true });
                NotificationPlugin.getToken().then(async (firebaseToken) =>
                {
                    if (firebaseToken.value) await channel.auth({ type: "storeFirebaseToken", firebaseToken: firebaseToken.value });
                });
                break;

            case 'logout':
                setState({ isAuthenticated: false });
                break;

        }
    });

    const login = async (username: string, password: string) =>
    {
        await channel.auth({ type: "login", username, password });
        return true;
    };

    const logout = async () =>
    {
        await channel.auth({ type: "logout", token: state.accessToken || "" });
        return true;
    };



    return { login, logout, onStateChange, isAuthenticated };

};

const { default: WithAuth, AuthInvalid, AuthValid, AuthPending, useAuth } = authContext;

export const useMyProfileId = () =>
{
    const channel = useWebsocket();
    const [id, setId] = useState("");


    if (!id) {

        channel.once('auth:response', (response: AuthRequest) =>
        {
            switch (response.type) {
                case 'me':
                    setId(response.id || "");
                    break;
            }
        });

        channel.auth({
            'type': 'me'
        });

    }

    return id;

};

export const useMyProfile = () =>
{
    const me = useMyProfileId();
    const profile = useUser(me);

    return profile;
};

export const useMyDetails = () =>
{
    const me = useMyProfileId();
    const details = useUserDetails(me);

    return details;
};

export const useMyDateFormat = () =>
{
    const { dateTimeFormat, timezone } = useMyDetails();
    return (date: Date = new Date()) => getDateFormat(dateTimeFormat!, date, timezone);
};

export const useMyPermissions = () =>
{
    const me = useMyProfileId();
    const permissions = useUserPermissions(me);

    return permissions;
};

export const useForgotPassword = () =>
{
    const channel = useWebsocket();

    return async (username: string) =>
    {
        channel.auth({ type: "forgotPassword", username });
        return true;
    };

};

// export const useSendDeveloperInformation = () =>
// {
//     const channel = useWebsocket();

//     return async (token: string) =>
//     {
//         //@ts-ignore
//         channel.auth({ type: "developerInformation", token });
//         return true;
//     };

// };

export const useResetPassword = () =>
{
    const channel = useWebsocket();

    return async (token: string, password: string) =>
    {
        channel.auth({ type: "resetPassword", token, password });
        return true;
    };

};

const LoginProvider: FC<PropsWithChildren> = ({ children }) =>
{

    const channel = useWebsocket();
    const [provider, setProvider] = useState(websocketProvider(channel, new persistence.LocalStorage()));
    provider.onStateChange(() => setProvider({ ...provider }));

    return <WithAuth provider={provider}>{children}</WithAuth>;

};

export default LoginProvider;
export
{
    AuthInvalid, AuthPending, AuthValid, useAuth
};
