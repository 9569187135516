import { IonCard, IonCardContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonSegmentButton, IonText } from "@ionic/react";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { useL10n } from "@ews/react-localization-context";
import { SystemElement, SystemGroup } from "../ReactData/system";
import { SystemPlan } from "@ews/react-data";
import { add, remove } from "ionicons/icons";
import React, { ReactNode, useEffect, useRef, useState } from "react";

import Fullscreen from "../Fullscreen/Fullscreen";

import { useNamedRoutes } from "../NamedRoutes";
import
{
    useModifySystemElement,
    useModifySystemPlan,
    useSystemPlan,
    useSystemSetup
} from "../ReactData/system";
import { useIsMobile } from "../Responsive";
import SettingsSegment from "../Settings/SettingsSegment/SettingsSegment";
import SystemPlanPageAddBtn from "./Components/AddButton/AddButton";
import DropZone from "./Components/DropZone/DropZone";
import { DeviceLocation } from "./Components/DropZone/types";
import Elements from "./Components/ElementList/ElementList";
import GroupButton from "./Components/GroupButton/GroupButton";

import NewPlanPageModal from "./Components/NewPlanPage/NewPlanPage";
import style from './Plan.module.scss';
import { GroupObj, PlanProps } from "./types";
import DeviceList from "./Components/DeviceList/DeviceList";
import { NumberGroup } from "@ews/zlt-events";


const Plan: React.FC<PlanProps> = ({ children, system }) =>
{
    const { params } = useNamedRoutes();

    const modifySystemElement = useModifySystemElement();
    const modifySystemPlan = useModifySystemPlan();

    const { id } = params();

    let setup = useSystemSetup(id!);
    let plan = useSystemPlan(id!);

    const mobileView = useIsMobile();
    const [currentSegment, setCurrentSegment] = useState<string>(plan[0]?.id ? plan[0].id.toString() : "0");
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [imagePath, setImagePath] = useState("");
    const [currentElement, setCurrentElement] = useState<SystemElement>({} as SystemElement);

    const [currentGroup, setCurrentGroup] = useState(NumberGroup.Zone);

    const filter = useRef<HTMLIonInputElement>(null);

    const [planList, setPlanList] = useState<SystemPlan[]>([]);

    const [planElementList, setPlanElementList] = useState<Record<string, SystemElement[]>>({});

    const slideToRef = useRef<number>(0);

    const [groupList, setGroupList] = useState<GroupObj | null>(null);

    function saveElementPosition(id: string, data: Record<string, any>)
    {
        modifySystemElement(id, { ...data } as SystemElement);
    }
    function removePlanPage(systemId: string, id: string | number)
    {
        const pages = plan.filter(page => typeof page.id === "string" ? `${page.id!}` !== `${id!}` : page.id !== id);
        //modifySystemPlan(systemId, pages);
    }
    useEffect(() =>
    {
        console.count('useEffect setup');
        if (setup.groups.length > 0) {

            const grouplist: GroupObj = {
                [NumberGroup.Zone]: { list: [], title: "[SID_MNU_TAB_GROUPS]" },
                //Control: {list:[],title:"[SID_MNU_TAB_CONTROLS]"},
                [NumberGroup.ActuationDevice]: { list: [], title: "extinguish groups" },
                [NumberGroup.FloodingZone]: { list: [], title: "[SID_MNU_TAB_GROUPS]" },
                [NumberGroup.AlarmingDevice]: { list: [], title: "[SID_MNU_TAB_AL_DEVICES]" },
                [NumberGroup.TransmissionDevice]: { list: [], title: "[SID_MNU_TAB_TR_DEVICES]" },
            };
            const ElementsForPlanList: Record<string, SystemElement[]> = {};
            if (plan.length > 0) {
                setPlanList(plan);
                plan.forEach(p =>
                {
                    //planElementList.current = { ...planElementList.current, [`${p.id}`]: [] };
                    ElementsForPlanList[`${p.id}`] = [];
                });
            }
            setup.groups.forEach((group: SystemGroup) =>
            {

                if (group.numberGroup) {
                    const type = group.numberGroup as keyof GroupObj;
                    const ElementsForGroupList: SystemElement[] = [];

                    if (group.elements) {

                        group.elements.forEach((element: SystemElement, index: number) =>
                        {
                            if (Object.keys(ElementsForPlanList).length > 0 && element.systemPlanX !== null && element.systemPlanId !== null) {
                                ElementsForPlanList[`${element.systemPlanId}`].push(element);
                            }
                            else {
                                ElementsForGroupList.push(element);
                            }
                        });
                    }
                    if (ElementsForGroupList.length) {
                        const group2 = { ...group };
                        group2.elements = ElementsForGroupList;
                        grouplist[type].list.push(group2 as SystemGroup);
                    }
                }


            });
            setPlanElementList(ElementsForPlanList);
            setGroupList(grouplist);

        }
    }, [setup, plan]);

    const { translate: t } = useL10n();

    const SwiperRef = useRef<any>(); // type ist SwiperRef aber dann funktioniert SwiperRef.current.click() nicht 

    const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
    {
        const swiper = useSwiper();
        return <IonInput
            label=""
            ref={swipeRef}
            onClick={(e) =>
            {

                swiper.slideTo(slideToRef.current);
            }}
            hidden />;
    };

    const SwiperComponente = mobileView ? Swiper : (props: any) => <div {...props}></div>;
    const SliderComponente = mobileView ? SwiperSlide : ({ children, current, id }: { onClick: () => void, current: string, id: string, children: ReactNode; }) => <div hidden={current !== id}>{children}</div>;

    return <section
        id="PlanContent"
        className={`${style.containerSize} ${mobileView ? '' : style.flex} ${mobileView ? '' : 'ion-padding'}`}>
        {mobileView ? <SettingsSegment value={currentSegment} onSelect={(current: string) => setCurrentSegment(current)}>
            {plan.map((button, index) =>
            {
                return <IonSegmentButton
                    key={index}
                    value={button.id?.toString()}
                    onClick={() =>
                    {
                        slideToRef.current = index;
                        SwiperRef.current.click();
                    }}>
                    <IonLabel>{button.label}</IonLabel>
                </IonSegmentButton>;
            })}
        </SettingsSegment> : null}

        {mobileView ? null : <SystemPlanPageAddBtn
            icon={add}
            color={"success"}
            onClick={function (): void
            { }} />}
        <div className={style[mobileView ? 'mobile' : 'desktop']} >
            <>
                <SwiperComponente
                    /*   ref={SwiperRef} */

                    slidesPerView={1}
                    spaceBetween={0}
                    onSlideChange={(e: any) =>
                    {
                        slideToRef.current = e.activeIndex;
                        if (mobileView) setCurrentSegment(plan[0].id!.toString());
                        else setCurrentPage(e.activeIndex);

                    }}
                >
                    {mobileView ? <SwiperButtonNext swipeRef={SwiperRef} /> : null}
                    {plan.map((page, index) =>
                    {
                        return <SliderComponente
                            key={index}
                            current={currentSegment}
                            id={page.id!.toString()}
                            onClick={() => setCurrentPage(index)}>
                            <DropZone
                                currentElement={currentElement}
                                imagePath={imagePath}
                                title={page.label}
                                elementList={planElementList[`${page.id}`] || []}
                                onData={(data) =>
                                {
                                    const { groupId, numberGroup, id } = currentElement;
                                    const planId = page.id;
                                    if (currentElement) {
                                        saveElementPosition(system.id!, { planId, groupId, numberGroup, id, ...data });// plan: page,
                                    } else {
                                        saveElementPosition(system.id!, { ...data });
                                    }
                                }}
                            />
                        </SliderComponente>;
                    })}
                </SwiperComponente>
            </>
        </div>
        {mobileView ? null : <Fullscreen><div className={`${style.position}`}>
            <aside className={`${style.toolContainer} `}>
                <span className={`${style.marginTop} `}>
                    {planList.map((button, index) =>
                    {
                        return <IonItem
                            lines="none"
                            style={{ marginBottom: "10px " }}
                            key={`pagebutton-${index}`}
                            color={currentPage === index ? 'primary' : 'secondary'}
                            //expand="full"
                            onClick={() =>
                            {
                                setCurrentPage(index);
                                setCurrentSegment(button.id!.toString());
                                if (mobileView) {
                                    slideToRef.current = index;
                                    SwiperRef.current?.click();
                                };

                            }}>
                            <IonText style={{ width: "90%" }} >{button.label}</IonText>

                            <IonIcon style={{ width: "10%" }} color="danger" icon={remove} onClick={() =>
                            {
                                removePlanPage(system.id!, button.id!);
                            }} />
                        </IonItem>;
                    })}
                </span>
                <IonCard className={`${style.card}`}>
                    {/*   <IonCardHeader
                        color={'primary'}
                    > */}

                    {groupList ? Object.keys(groupList).map((group, index) =>
                    {

                        const key = group as unknown as keyof GroupObj;
                        return groupList[key].list.length ?
                            <GroupButton
                                key={`group-${index}`}
                                color={key === currentGroup ? "primary" : "secondary"}
                                title={t(groupList[key].title)}
                                onClick={() => setCurrentGroup(key)} />
                            :
                            <React.Fragment key={index}></React.Fragment>;
                    })
                        : null
                    }
                    {/*  </IonCardHeader> */}
                    <IonCardContent>
                        <DeviceList
                            current={""}
                            InputValue={filter.current?.value?.toString() || ""}
                            text={"Device"}
                            onSelect={({ image }) =>
                            {
                                setImagePath(image);
                            }} />
                        <IonList
                            className={style.deviceList}
                        >
                            {groupList ? Object.keys(groupList).map((group, index) =>
                            {
                                const key = group as unknown as keyof GroupObj;

                                return groupList[key].list.length ?

                                    groupList[key].list.map((group, index) =>
                                    {
                                        if (group.elements?.length === 0) return <React.Fragment key={index} />;
                                        return <Elements
                                            //draggable={imagePath.length > 0}
                                            disabled={imagePath.length === 0}
                                            hidden={!(key === currentGroup)}
                                            current={currentElement}
                                            key={`element-${group.id}-${index}`}
                                            list={group.elements! as DeviceLocation[]}
                                            onDrag={(element) =>
                                            {
                                                setCurrentElement(element);
                                            }}
                                            onClick={(element) =>
                                            {
                                                if (element.id === currentElement.id) setCurrentElement({} as SystemElement);
                                                else setCurrentElement(element);
                                            }}
                                        />;

                                    })

                                    :
                                    <React.Fragment key={index}></React.Fragment>;
                            })
                                : null
                            }
                        </IonList>
                    </IonCardContent>
                </IonCard>
            </aside>
        </div>
        </Fullscreen>
        }
        <NewPlanPageModal systemId={id!} list={plan} />
    </section >;
};

export default Plan;