import { useSystemShare } from '../../../../../../../ReactData/system';
import SettingsCol from '../../../../../../../Settings/SettingsCol/SettingsCol';
import SettingsLabel from '../../../../../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel';
import { Props } from './types';

const General: React.FC<Props> = ({ systemId, userId }) =>
{

    return <></>;
    /* const share = useSystemShare(systemId || "", userId);
    console.log(share);
    return <SettingsCol
    //size='12' xl='12' md='12' lg='12' sm='12'
    >
        <SettingsLabel text={'System-ID'} value={share.systemNumber} />
        <SettingsLabel text={'Bearbeitet am'} value={share.modified} />
        <SettingsLabel text={'Erstellt am'} value={share.created} />
        <SettingsLabel text={'Geändert durch Benutzer'} value={''} />
        <SettingsLabel text={'Erstellt durch Benutzer'} value={''} />
        <SettingsLabel text={'Kunden-ID'} value={share.customerNumber} />
        <SettingsLabel text={'Kundenname'} value={share.customerName} />
        <SettingsLabel text={'Benuzter-ID'} value={share.username} />
        <SettingsLabel text={'Benuztername'} value={[share.userLastName, share.userFirstName].join(", ")} />
    </SettingsCol>;
    <SettingsCol />;
    <SettingsCol />; */
};
export default General;
