
import
{
    Customer,
    CustomerDetails,
    CustomerPermissions,
    PaginationRequest
} from '@ews/react-data';

import
{
    useLoadingIndicator
} from "../Loading";

import
{
    useTopicData,
    useWebsocket
} from './ReactDataProvider';

import
{
    ReactTopics
} from '@ews/websocket-service';

import
{
    useEffect,
    useState
} from 'react';


export const useCustomer = (customerId: string) =>
{
    return useTopicData<Customer>(`${ReactTopics.customer.withParams({ customerId })}`, {} as Customer);
};

export const useCustomerDetails = (customerId: string) =>
{
    return useTopicData<CustomerDetails>(`${ReactTopics.customerDetails.withParams({ customerId })}`, {} as CustomerDetails);
};

export const useCustomerPermissions = (customerId: string) =>
{
    return useTopicData<CustomerPermissions>(`${ReactTopics.customerPermissions.withParams({ customerId })}`, {} as CustomerPermissions);
};
export const useMyCustomers = () =>
{
    const provider = useWebsocket();
    const [customers, setCustomers] = useState<Customer[]>([]);

    useEffect(() =>
    {
        const handleCustomer = (customerReceived: Customer) =>
        {
            setCustomers((customers) =>
            {
                return customers.map((customer) =>
                {
                    return customer.id === customerReceived.id ? customerReceived : customer;
                });
            });
        };

        const handleCustomers = ({ results }: { results: Customer[]; }) =>
        {
            const customersReceived = results;
            setCustomers((customers) =>
            {
                customers.forEach((customer) =>
                {
                    const topic = ReactTopics.customer.withParams({ customerId: customer.id! });
                    provider.unsubscribe(`${topic}`, handleCustomer);
                });

                customersReceived.forEach((customer) =>
                {
                    const topic = ReactTopics.customer.withParams({ customerId: customer.id! });
                    provider.subscribe(`${topic}`, handleCustomer);
                });

                return customersReceived;
            });
        };

        provider.bind(`${ReactTopics.myCustomers}`, handleCustomers);

        return () =>
        {
            provider.unsubscribe(`${ReactTopics.myCustomers}`, handleCustomers);
        };

    }, [provider]);

    return customers;
};



export const useModifyCustomer = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (customerId: string, data: Customer) => provider.modify(data, `${ReactTopics.customer.withParams({ customerId })}`, loadingToggle);
};

export const useModifyCustomerDetails = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (customerId: string, data: CustomerDetails) => provider.modify(data, `${ReactTopics.customerDetails.withParams({ customerId })}`, loadingToggle);
};

export const useModifyCustomerPermissions = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (customerId: string, data: CustomerPermissions) => provider.modify(data, `${ReactTopics.customerPermissions.withParams({ customerId })}`, loadingToggle);
};


export const useCreateCustomer = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (name: string, parentId?: string) =>
    {
        const customer: Customer = { name, parentId } as Customer;
        return await provider.create(customer, `${ReactTopics.customerCreate}`, loadingToggle);
    };
};

export const useFetchCustomerList = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch(filterCriteria, `${ReactTopics.myCustomers}`, loadingToggle);
    };
};
export const useTypeAheadCustomerList = () =>
{
    const provider = useWebsocket();

    return async (filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch(filterCriteria, `${ReactTopics.myCustomers}`);
    };
};