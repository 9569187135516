
import { SystemElement } from "../../../ReactData/system";

import { IonItem } from "@ionic/react";
import React from "react";

import listStyle from "../GruppList.module.scss";
import
{
    HeaderOption,
} from "../types";



import
{
    SystemGroup
} from "@ews/react-data";

import LongPress from "../../../LongPress";
import
{
    ReactClickEvent
} from "../../../types";

type ElementProps = {
    headers: HeaderOption[],
    row?: SystemElement;
    group: SystemGroup;
    color?: string,
    slot?: string;
    onMenu?: (group: SystemGroup, element?: SystemElement, e?: ReactClickEvent) => void;
};

export const Element: React.FC<ElementProps> = ({
    row,
    headers,
    group,
    color,
    slot,
    onMenu
}) =>
{
    const menuWrapper = (e?: ReactClickEvent) =>
    {
        if (onMenu) {
            onMenu(group, row, e);
        }
    };

    return <IonItem
        slot={slot}
        color={color}
        lines={"none"}
    >

        <div
            className={`${listStyle.row} ${listStyle.noExpand}`}
            {...LongPress(menuWrapper)}
            onContextMenu={menuWrapper}
        >
            {headers.map((header, index) =>
            {
                const { render } = header;
                return <React.Fragment key={index}>{render(group, row)}</React.Fragment>;

            })}

        </div>

    </IonItem >;
};;
