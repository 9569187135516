import
{
    IonButtons,
    IonToolbar
} from "@ionic/react";

import
{
    FC,
    PropsWithChildren
} from "react";

import
{
    NetworkStatus
} from "../../NetworkStatus";

const Main: FC<PropsWithChildren> = ({ children }) =>
{
    return (<>
        <IonToolbar >
            <IonButtons style={{ gap: "15px", padding: "5px" }} slot="end">
                {children}
                <NetworkStatus />
            </IonButtons>
        </IonToolbar>


    </>);
};

export default Main;