import { IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import SettingsTableColumn from "../../../../../Settings/SettingsComponents/SettingsTable/SettingsTableColumn/SettingsTableColumn";
import Checkbox from "../Checkbox/Checkbox";
import { useL10n } from "@ews/react-localization-context";
import { Options } from "./types";
import { NotificationChannel } from "@ews/react-data";


const PushOption: React.FC<Options> = ({
    size,
    style,
    label = "",
    disabled = false,
    hidden = false,
    center,
    value,
    options,
    onChange,
    onSelect,
    sound
}) =>
{

    const { translate: t } = useL10n();

    function onChangeHandler(selectedEvent: number, checked: boolean)
    {
        value = checked ? value | selectedEvent : value ^ selectedEvent;
        if (onChange) onChange(value);
    }
    if (hidden) return <></>;
    return <>
        <SettingsTableColumn size="4" center={center}>
            <IonLabel style={style}>{t(label)}</IonLabel>
        </SettingsTableColumn>
        {"push" in options && <SettingsTableColumn size={size}>
            <Checkbox
                disabled={disabled}
                text={options.push || "begin"}
                checked={(value & NotificationChannel.PUSH) > 0}
                onChange={(checked) =>
                {
                    onChangeHandler(NotificationChannel.PUSH, checked);
                }} />
        </SettingsTableColumn>}

        {"push_end" in options && <SettingsTableColumn size={size}>
            <Checkbox
                disabled={disabled}
                text={options.push_end || "end"}
                checked={(value & NotificationChannel.PUSH_END) > 0}
                onChange={(checked) =>
                {
                    onChangeHandler(NotificationChannel.PUSH_END, checked);
                }} />
        </SettingsTableColumn>}

        {"email" in options && <SettingsTableColumn size={size}>
            <Checkbox
                disabled={disabled}
                text={options.email || "begin"}
                checked={(value & NotificationChannel.EMAIL) > 0}
                onChange={(checked) =>
                {
                    onChangeHandler(NotificationChannel.EMAIL, checked);
                }} />
        </SettingsTableColumn>}

        {"email_end" in options && <SettingsTableColumn size={size}>
            <Checkbox
                disabled={disabled}
                text={options.email_end || "end"}
                checked={(value & NotificationChannel.EMAIL_END) > 0}
                onChange={(checked) =>
                {
                    onChangeHandler(NotificationChannel.EMAIL_END, checked);
                }} />
        </SettingsTableColumn>}

        {onSelect && <SettingsTableColumn size={size}>
            <IonSelect style={{ minWidth: '150px', marginLeft: '90px' }}
                disabled={disabled}
                value={sound || "none"}
                onIonChange={({ detail }) => onSelect(detail.value || "")}
            >
                <IonSelectOption value={"default"}>{t("Standard tone of end device")}</IonSelectOption>
                <IonSelectOption value={"sawtooth"}> {t("Fire 1 (sawtooth)")}</IonSelectOption>
                <IonSelectOption value={"beep"}>  {t("Fire 2 (beep)")}</IonSelectOption>
                <IonSelectOption value={"triple beep"}>{t("Fire 3 (triple beep)")}</IonSelectOption>
                <IonSelectOption value={"bell"}>{t("Fire 4 (bell)")}</IonSelectOption>
                <IonSelectOption value={"none"}>{t("none")}</IonSelectOption>
            </IonSelect>
        </SettingsTableColumn>}
    </>;
};

export default PushOption;