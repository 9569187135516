import { useL10n } from "@ews/react-localization-context";
import { IonButton, IonGrid, IonInput } from "@ionic/react";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import
{
    NumberGroup
} from '@ews/zlt-events';

import { SystemGroup } from "@ews/react-data";
import { FC, ReactNode, useRef, useState } from "react";
import { useIsMobile } from "../../Responsive";
import SettingsSwiper from "../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper";
import GroupItem from "../GroupItem/GroupItem";
import { firstLetterUpperCase } from "./helper";
import groupStyle from "./GroupOverview.module.scss";

const SliderComponentDesktop: FC<{
    children: React.ReactNode,
    className?: string;
}> = ({ children, className }) => <section className={`${groupStyle["slider-section-componente"]} ${className}`} > {children}</section >;

const Container: FC<{ className?: string, children: ReactNode, style?: Record<string, string>; }> = ({ children, style, className }) =>
{
    const mobileView = useIsMobile();
    return mobileView ? <>{children}</> : <div className={className} style={style}>{children}</div>;
};

const GroupOverview: React.FC<{ groups: SystemGroup[]; }> = ({ groups }) =>
{
    const slideToRef = useRef<number>(0);
    const SwiperRef = useRef<any>();

    const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
    {
        const swiper = useSwiper();
        return <IonInput
            label=""
            ref={swipeRef}
            onClick={(e) =>
            {

                swiper.slideTo(slideToRef.current);
            }}
            hidden />;
    };

    const buttonTitelList = ["[SID_MNU_TAB_GROUPS]", "[SID_MNU_TAB_CONTROLS]", "extinguish groups", "[SID_MNU_TAB_AL_DEVICES]", "[SID_MNU_TAB_TR_DEVICES]"];

    type List = {
        [NumberGroup.Zone]: SystemGroup[];
        [NumberGroup.ActuationDevice]: SystemGroup[];
        [NumberGroup.FloodingZone]: SystemGroup[];
        [NumberGroup.AlarmingDevice]: SystemGroup[];
        [NumberGroup.TransmissionDevice]: SystemGroup[];
    };

    const grouplist: List = {
        [NumberGroup.Zone]: [],
        [NumberGroup.ActuationDevice]: [],
        [NumberGroup.FloodingZone]: [],
        [NumberGroup.AlarmingDevice]: [],
        [NumberGroup.TransmissionDevice]: [],
    };

    groups.forEach((group) =>
    {
        const type = group.numberGroup as keyof List;
        grouplist[type].push(group);
    });

    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    const [currentPage, setCurrentPage] = useState(buttonTitelList[0]);

    const SwiperComponent = mobileView ? SettingsSwiper : IonGrid;
    const SliderComponent = mobileView ? SwiperSlide : SliderComponentDesktop;

    return (<>
        <div className={`${groupStyle["group-card-content-container"]} ${mobileView ? '' : 'ion-padding'}`} >
            <SwiperComponent loop
                className={groupStyle["group-swiper"]}
            >
                {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}
                {mobileView ? <SliderComponent key={'group-overview-slider-main'} className={mobileView ? groupStyle["slide-height"] : ""}>
                    <div className={groupStyle.buttonContainer}>
                        {buttonTitelList.map((title, index) =>
                        {
                            return <div key={index} className={groupStyle["button-wrapper"]} data-x={title}>
                                <IonButton
                                    expand="full"
                                    color='primary'
                                    className={groupStyle["gp-btn"]}
                                    onClick={() =>
                                    {
                                        slideToRef.current = index + 1;
                                        SwiperRef.current.click();
                                    }}
                                >
                                    <div className={groupStyle["pull-left"]}>{t(firstLetterUpperCase(title))}</div>

                                </IonButton>
                            </div>;
                        })}
                    </div>
                </SliderComponent> : null}

                <Container className={groupStyle['container']}>

                    <Container className={groupStyle['group-container']}>

                        {
                            mobileView ? <>
                                <SliderComponent className={groupStyle['group-swiper']}>
                                    <GroupItem groups={grouplist ? grouplist[NumberGroup.Zone] : []} title={buttonTitelList[0]} />
                                </SliderComponent>

                                <SliderComponent className={groupStyle['group-swiper']}>
                                    <GroupItem groups={grouplist ? grouplist[NumberGroup.ActuationDevice] : []} title={buttonTitelList[1]} />
                                </SliderComponent>

                                <SliderComponent className={groupStyle['group-swiper']}>
                                    <GroupItem groups={grouplist ? grouplist[NumberGroup.FloodingZone] : []} title={buttonTitelList[2]} />
                                </SliderComponent>

                                <SliderComponent className={groupStyle['group-swiper']}>
                                    <GroupItem groups={grouplist ? grouplist[NumberGroup.AlarmingDevice] : []} title={buttonTitelList[3]} />
                                </SliderComponent>

                                <SliderComponent className={groupStyle['group-swiper']}>
                                    <GroupItem groups={grouplist ? grouplist[NumberGroup.TransmissionDevice] : []} title={buttonTitelList[4]} />
                                </SliderComponent>

                            </> : <>
                                <GroupItem className={currentPage === buttonTitelList[0] ? "" : groupStyle.none} groups={grouplist ? grouplist[NumberGroup.Zone] : []} title={buttonTitelList[0]} />
                                <GroupItem className={currentPage === buttonTitelList[1] ? "" : groupStyle.none} groups={grouplist ? grouplist[NumberGroup.ActuationDevice] : []} title={buttonTitelList[1]} />
                                <GroupItem className={currentPage === buttonTitelList[2] ? "" : groupStyle.none} groups={grouplist ? grouplist[NumberGroup.FloodingZone] : []} title={buttonTitelList[2]} />
                                <GroupItem className={currentPage === buttonTitelList[3] ? "" : groupStyle.none} groups={grouplist ? grouplist[NumberGroup.AlarmingDevice] : []} title={buttonTitelList[3]} />
                                <GroupItem className={currentPage === buttonTitelList[4] ? "" : groupStyle.none} groups={grouplist ? grouplist[NumberGroup.TransmissionDevice] : []} title={buttonTitelList[4]} />
                            </>
                        }
                    </Container>
                    {!mobileView ? <div className={groupStyle.buttonContainer}>
                        {buttonTitelList.map((title, index) =>
                        {
                            return <div key={index}>
                                <IonButton
                                    expand="full"
                                    color={currentPage === title ? 'secondary' : 'primary'}
                                    onClick={() => { setCurrentPage(title); }}
                                >{t(firstLetterUpperCase(title))}</IonButton>
                            </div>;
                        })}
                    </div> : null}
                </Container>
            </SwiperComponent>
        </div >
    </>
    );
};
export default GroupOverview;