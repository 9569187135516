import
{
    FC
} from "react";

import
{
    SystemStatus
} from "../../System/SystemStatus";

import
{
    AuthorizationGeoCoordinate,
    AuthorizationPermission,
    AuthorizationReactSession,
    AuthorizationStatus,
    AuthorizationSystemAuthorization,
    AuthorizationWLanSSID
} from "../../Authorization";

import Main from "./Main";


const System: FC = () =>
{
    return (<Main>
        <AuthorizationReactSession />
        <AuthorizationSystemAuthorization />
        <AuthorizationGeoCoordinate />
        <AuthorizationWLanSSID />
        <AuthorizationPermission />
        <AuthorizationStatus />
        <SystemStatus />
    </Main>);
};

export default System;