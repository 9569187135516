import { useL10n } from "@ews/react-localization-context";
import { IonContent, IonInput, IonLabel, IonSegmentButton } from "@ionic/react";
import React, { FC, useRef, useState } from "react";
import { SwiperSlide, useSwiper } from "swiper/react";
import Overflow from "../../../Overflow/Overflow";
import { useIsMobile } from "../../../Responsive";
import SettingsSwiper from "../../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper";
import SettingsGrid from "../../../Settings/SettingsGrid/SettingsGrid";
import SettingsSegment from "../../../Settings/SettingsSegment/SettingsSegment";
import DetailsSettings from "../DetailsSettings/DetailsSettings";
import LicenseSettings from "../LicenseSettings/LicenseSettings";
import MainSettings from "../MainSettings/MainSettings";
import MaintenanceReportsSettings from "../MaintenanceReportsSettings/MaintenanceReportsSettings";
import NotificationsSettings from "../NotificationsSettings/NotificationsSettings";
import PermissionsSettings from "../PermissionsSettings/PermissionsSettings";
import { DisplayTabProps, SettingsOverlayProps } from "../types";
import style from './SettingsOverlay.module.scss';
import GPSSnapshot from "../GPSSnapshot/GPSSnapshot";


export const DisplayTab: React.FC<DisplayTabProps> = ({ name, display, children }) =>
{
    if (name === display) {
        return <>{children}</>;
    }
    return <></>;
};

const SettingsOverlay: React.FC<SettingsOverlayProps> = ({ systemId, onClose }) =>
{


    type Page = {
        value: string;
        title: string;
        child: FC<{ systemId: string; }>;
    };

    const pageList: Page[] = [
        {
            value: 'general',
            title: "General",
            child: MainSettings
        },
        {
            value: 'details',
            title: "Details",
            child: DetailsSettings
        },
        {
            value: 'rights',
            title: "Rights",
            child: PermissionsSettings
        },
        {
            value: 'license',
            title: "License",
            child: LicenseSettings
        },
        {
            value: 'notifications',
            title: "Notifications",
            child: NotificationsSettings
        },
        {
            value: 'gpsdetails',
            title: "GPS Details",
            child: GPSSnapshot
        },
        {
            value: 'maintenance reports',
            title: "Maintenance reports",
            child: MaintenanceReportsSettings
        },
    ];
    const mobileView = useIsMobile();
    const SliderComponent = mobileView ? SwiperSlide : ({ children }: { children: React.ReactNode; }) => <>{children}</>;
    const [currentSegment, setCurrentSegment] = useState<string>('general');
    const slideToRef = useRef<number>(0);
    const SwiperRef = useRef<any>();

    const { translate: t } = useL10n();

    const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
    {
        const swiper = useSwiper();
        return <IonInput
            label=""
            ref={swipeRef}
            onClick={() => swiper.slideTo(slideToRef.current)}
            hidden />;
    };

    return <>
        <IonContent id="settingsContent" className={style.overlaySettings} fullscreen>

            <SettingsSegment value={currentSegment} onSelect={(current: string) => setCurrentSegment(current)}>
                {pageList.map((segment, index) => (

                    <IonSegmentButton key={segment.value} value={segment.value} onClick={() =>
                    {
                        if (mobileView) {
                            slideToRef.current = index;
                            SwiperRef.current.click();
                        }
                    }}>
                        <IonLabel>{t(segment.title)}</IonLabel>
                    </IonSegmentButton>
                ))}
            </SettingsSegment>


            <SettingsSwiper onSlideChange={(index) =>
            {
                slideToRef.current = index;
                setCurrentSegment(pageList[index].value);

            }}>
                {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}

                {pageList.map((page, index) =>
                {
                    const { child, value } = page;
                    const Component = child;
                    return <SliderComponent key={index}>
                        <DisplayTab name={value} display={currentSegment}>
                            <Overflow minHeight={"80vh"} maxHeight={"87vh"}>
                                <SettingsGrid>
                                    <Component systemId={systemId} />
                                </SettingsGrid>
                            </Overflow>
                        </DisplayTab>
                    </SliderComponent>;
                })}

            </SettingsSwiper>

        </IonContent>
    </>;

};

export default SettingsOverlay;