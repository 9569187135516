import { useL10n } from "@ews/react-localization-context";
import { formatLogicalAddress } from "@ews/zlt-events";
import { IonButton, IonCard, IonItem, IonList, IonText } from "@ionic/react";
import React from "react";
import { useIsMobile } from "../../../../Responsive";
import dropStyle from "../DropZone.module.scss";
import { ContextMenuProps } from "./types";

const ContextMenu: React.FC<ContextMenuProps> = ({
    menuColor,
    positionsCorrection,
    currentMenu,
    id,
    element,
    onChange,
    onBlur,
    onData,
    onMenu
}) =>
{

    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    //const [rerender, setRerender] = useState(false);
    /*  const x = useRef<HTMLIonInputElement>(null);
     const y = useRef<HTMLIonInputElement>(null); */

    const deviceId = `${formatLogicalAddress(element.groupId!, element.id!, 1)}`;

    /*   useEffect(() =>
      {
          if (positionsCorrection && positionsCorrection[deviceId]) {
              x.current!.value = positionsCorrection[deviceId].planX;
              y.current!.value = positionsCorrection[deviceId].planY;
          } else {
              x.current!.value = element.planX;
              y.current!.value = element.planY;
          }
      }, [x, y]); // positionsCorrection in das Array gebe kann ich in den Inputs die values erzeugen aber auch ein endloss rerender
   */
    return mobileView ? null : <IonCard
        color={menuColor}
        className={dropStyle.menu}
        hidden={currentMenu !== id}>
        <IonList>
            <IonItem lines="full">
                <IonText slot="start">{`${element.text}`}</IonText>
                <IonText slot="end">{`${deviceId}`}</IonText>
            </IonItem>
            <IonItem lines="full">
                <IonText>{`${element.numberGroup}`}</IonText>
            </IonItem>
            <IonItem lines="full">
                {/* <Icon icon={eventDetails(element.eventKindType!).symbol} /> */}
            </IonItem>
        </IonList>
        {/*    <IonItem
            color={menuColor}
        >
            <IonLabel position="stacked">{t('Left/Right')}
            </IonLabel>
            <IonInput
            label=""
                type="number"
                ref={x}
                value={element.planX}
                onIonChange={({ detail }) =>
                {
                    const planX = Number(detail.value);
                    if (onChange) onChange(deviceId, { planX, planY: Number(y.current!.value!) });

                    //if (onData) onData({planX});
                    //setRerender(!rerender);
                }} />
        </IonItem>
        <IonItem
            color={menuColor}
        >
            <IonLabel position="stacked">{t('Top/Bottom')}
            </IonLabel>
            <IonInput
            label=""
                type="number"
                ref={y}
                value={element.planY}
                onIonChange={({ detail }) =>
                {
                    const planY = Number(detail.value);
                    //element.planY = planY;
                    //if (onData) onData({ planY });
                    if (onChange) onChange(deviceId, { planY, planX: Number(x.current!.value!) });
                    //setRerender(!rerender);
                }} />
        </IonItem> */}

        {/*    <IonButton expand="full" color={'success'} onClick={() =>
        {
            if (onMenu) onMenu(-1);
            const { groupId, groupType, id } = element;
            if (onData) onData({ planY: y.current?.value, planX: x.current?.value, groupId, groupType, id });

        }}>{t("Save")}</IonButton> */}
        <IonButton expand="full" color={'danger'} onClick={() =>
        {
            if (onMenu) onMenu(-1);

            if (onData) {
                const { groupId, numberGroup, id } = element;
                onData({ groupId, numberGroup, id });
            };

        }}>{t("Delete")}</IonButton>
        <IonButton expand="full" color={'tertiary'} onClick={() =>
        {
            if (onMenu) onMenu(-1);
        }}>{t("Close")}</IonButton>
    </IonCard>;
};




export default ContextMenu;