import { IonIcon } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import React, { useLayoutEffect, useRef, useState } from "react";
import style from './Overflow.module.scss';
import { OverflowProps, ScrollDirection } from "./types";


const Overflow: React.FC<OverflowProps> = ({ children, color, onScroll, minHeight, maxHeight }) =>
{
    const overflowRef = useRef<HTMLDivElement>(null);

    const [isScrollable, setIsScrollable] = useState<boolean>(false);
    const [direction, setDirection] = useState<ScrollDirection>('down');


    async function handle()
    {
        if (!overflowRef.current) return;

        let scrollable = false;
        const { y } = overflowRef.current.getBoundingClientRect();

        const { scrollHeight } = overflowRef.current;
        const visibleHeight = y > 0 ? window.innerHeight - y : window.innerHeight;

        if (scrollHeight > visibleHeight) scrollable = true;
        setIsScrollable(scrollable);
        if (onScroll) onScroll(scrollable);
    }

    const defaultStyle = { minHeight, maxHeight, overflow: "auto" };

    useLayoutEffect(() =>
    {
        setTimeout(() => handle(), 10);
    }, [children]);

    function handleScroll(e: React.UIEvent<HTMLElement, UIEvent>)
    {
        const target = e.target as HTMLDivElement;
        const { clientHeight, scrollHeight } = target;
        const scrollHeightMax = scrollHeight - clientHeight;

        setDirection(target.scrollTop !== scrollHeightMax ? "down" : "up");

    }

    return <section
        style={{ ...defaultStyle }}
        ref={overflowRef}
        onScroll={handleScroll}
    >

        {children}
        {isScrollable ?
            <span
                onClick={() =>
                {
                    const { clientHeight, scrollHeight } = overflowRef.current!;
                    const scrollHeightMax = scrollHeight - clientHeight;
                    overflowRef.current?.scrollTo({ behavior: "smooth", top: direction === "down" ? scrollHeightMax : 0 });
                }}
            >
                <IonIcon
                    color={color}
                    className={style.blinkArrow1}
                    icon={direction === "down" ? chevronDownOutline : chevronUpOutline}
                />
                <IonIcon
                    color={color}
                    className={style.blinkArrow2}
                    icon={direction === "down" ? chevronDownOutline : chevronUpOutline}
                />
                <IonIcon
                    color={color}
                    className={style.blinkArrow3}
                    icon={direction === "down" ? chevronDownOutline : chevronUpOutline}
                />
            </span>

            : null}
    </section>;
};
export default Overflow;