import
{
    useL10n
} from '@ews/react-localization-context';

import
{
    IonBadge,
    IonButton,
    IonCard,
    IonNote
} from '@ionic/react';

import
{
    FC,
    ReactNode,
    useEffect
} from 'react';

import
{
    EventCategoryIcon
} from '../Icons';

import
{
    useOperateSystem,
    useSystemPermissions,
    useSystemRTM
} from '../ReactData/system';

import
{
    useIsMobile
} from '../Responsive';

import buttonStyle from './ButtonContainer.module.scss';

import
{
    ButtonContainerProps
} from './types';

import
{
    useAuthorization
} from '../Authorization';

import
{
    AuthorizationLevel,
    NumberGroup,
    RTMCategory,
} from '@ews/zlt-events';

import
{
    getActionButton
} from '@ews/zlt-operate';

import Icon, {
    IconName
} from '@ews/react-icons';


import
{
    SystemOperate,
    SystemRTM,
    SystemStatus
} from '@ews/react-data';

import
{
    Timer
} from '../Icons/Timer';

import
{
    useSystemStatus
} from '../System/SystemStatus';

import
{
    distributeRTMFields
} from '../Event/helper.ts/distributeRTMFields';

import
{
    EventPriority
} from '../Event/types';

type ButtonProperties = {
    systemId: string;
    rtm: SystemRTM[],
};

const useButton = (systemId: string) =>
{
    const { authorizationLevel } = useAuthorization();
    const systemStatus = useSystemStatus(systemId);

    const canOperate = Boolean(systemId && systemStatus === SystemStatus.ONLINE);
    const mayOperate = Boolean(canOperate && authorizationLevel > AuthorizationLevel.LEVEL1);

    return { canOperate, mayOperate };;
};

const ButtonResetPanel: FC<ButtonProperties> = ({
    systemId
}) =>
{
    const permission = useSystemPermissions(systemId);
    const operate = useOperateSystem();
    const { mayOperate } = useButton(systemId);

    if (permission.exposePanelReset) {
        return <IonButton disabled={!mayOperate} className={buttonStyle["no-border"]} color={'danger'}
            onClick={async (e) =>
            {
                await operate(systemId, { command: 'reset' } as SystemOperate);
            }} ><p className={buttonStyle.reset}>RESET</p>
        </IonButton>;
    }
    return <></>;
};

const ButtonBuzzer: FC<ButtonProperties> = ({
    systemId,
    rtm
}) =>
{
    const permission = useSystemPermissions(systemId);
    const operate = useOperateSystem();

    const { canOperate } = useButton(systemId);
    const [icon, command, state] = getActionButton('Buzzer', rtm.map(rtm => rtm.eventKindType!));

    if (permission.exposeInternalBuzzer) {
        return <IonButton disabled={!canOperate || !command} className={buttonStyle[state]} color={'light'} onClick={async (e) =>
        {
            if (command) await operate(systemId!, { command } as SystemOperate);
        }} >
            <Icon icon={icon} />
        </IonButton >;
    }

    return <></>;
};

const IconTransDev: FC<{ icon: IconName; state: string; }> = ({
    icon,
    state
}) =>
{
    if (icon === 'none') {

        const time = state === 'reactionTimer' ? 30 : 600;
        const highlight = state === 'reactionTimer' ? "#e6001c" : "#efc100";

        return (<Timer
            className={buttonStyle.timer}
            highlight={highlight}
            strokeWidth={10}
            fontWeight={'bold'}
            fontSize={'15pt'}
            time={time}
        />);

    } else {
        return <Icon icon={icon} />;
    }
};

const ButtonTransDev: FC<ButtonProperties> = ({
    systemId,
    rtm
}) =>
{
    const permission = useSystemPermissions(systemId);
    const operate = useOperateSystem();

    const { mayOperate } = useButton(systemId);
    const [icon, command, state] = getActionButton('TransDev', rtm.map(rtm => rtm.eventKindType!));

    const operation = {
        numberGroup: NumberGroup.TransmissionDevice,
        firstZone: permission.transmittingDeviceElement,
        lastZone: permission.transmittingDeviceElement
    };

    if (permission.exposeTransmittingDevice) {
        return <IonButton disabled={!mayOperate} className={buttonStyle[state]} color={'light'} onClick={async (e) =>
        {
            if (command) await operate(systemId!, { command, ...operation } as SystemOperate);
        }} >
            <IconTransDev icon={icon} state={state} />
        </IonButton >;
    }

    return <></>;
};

const ButtonAlarmDev: FC<ButtonProperties> = ({
    systemId
}) =>
{
    const permission = useSystemPermissions(systemId);
    const operate = useOperateSystem();

    const rtm = useSystemRTM(systemId).map(rtm => rtm.eventKindType!);
    const { mayOperate } = useButton(systemId);
    const [icon, command, state] = getActionButton('AlarmDev', rtm);

    const operation = {
        numberGroup: NumberGroup.AlarmingDevice,
        firstZone: permission.alarmingDeviceFirstElement,
        lastZone: permission.alarmingDeviceLastElement,
    };

    if (permission.exposeAlarmingDevice) {
        return <IonButton disabled={!mayOperate} className={buttonStyle[state]} color={'light'} onClick={async (e) =>
        {
            if (command) await operate(systemId!, { command, ...operation } as SystemOperate);
        }} >
            <Icon icon={icon} />
        </IonButton >;
    }

    return <></>;
};

const ButtonsBackground = ({ color, children, className, mobileView = false }:
    { color?: string, children: ReactNode, className?: string, mobileView?: boolean; }) => mobileView ? <div className={`${className} ${buttonStyle.mobile}`}>{children}</div> :
        <IonCard color={color} className={`${className} ${buttonStyle.status}`}>{children}</IonCard>;

const ActionButtons = ({ children, className, mobileView = false }: { children: ReactNode, className: string, mobileView?: boolean; }) => mobileView ? <>{children}</> : <div className={className}>{children}</div>;

const ButtonContainer: React.FC<ButtonContainerProps> = ({
    systemId,
    rtm
}) =>
{

    const mobileView = useIsMobile();
    const { translate: t } = useL10n();

    useEffect(() =>
    {

    }, [systemId]);

    const rtmFields = distributeRTMFields(rtm);

    return (
        <ButtonsBackground color="tertiary" mobileView={mobileView}>

            {!mobileView && <div className={buttonStyle.events}>

                {EventPriority.map((categoryType) =>
                {
                    const category: RTMCategory = RTMCategory[categoryType];
                    const rtm: SystemRTM[] = rtmFields[category]!;

                    return <div key={categoryType}>
                        <div><EventCategoryIcon greyScale category={categoryType} /></div>
                        <IonNote >{t(`FC_${categoryType}`)}</IonNote>
                        <div><IonBadge color={'danger'} >{rtm.length}</IonBadge></div>
                    </div>;
                })}
            </div>}

            <ActionButtons className={buttonStyle.desktop} mobileView={mobileView}>
                <ButtonAlarmDev rtm={rtm} systemId={systemId!} />
                <ButtonTransDev rtm={rtm} systemId={systemId!} />
                <ButtonBuzzer rtm={rtm} systemId={systemId!} />
                <ButtonResetPanel rtm={rtm} systemId={systemId!} />
            </ActionButtons>
        </ButtonsBackground >
    );
};

export default ButtonContainer;;