import { useL10n } from "@ews/react-localization-context";

import
{
    batteryChargingOutline,
    buildOutline,
    callOutline,
    desktopOutline,
    documentTextOutline,
    enterOutline,
    keyOutline,
    listOutline,
    mapOutline,
    megaphoneOutline,
    options, serverOutline,
    statsChartOutline,
    swapVerticalOutline,
    terminalOutline,
    thermometerOutline,
    trashOutline
} from "ionicons/icons";

import { FC } from "react";
import
{
    MenuLink,
    MenuSpacer,
    Sidebar,
    SidebarSection
} from "./Sidebar";

import
{
    RouteParams,
    useNamedRoutes
} from "../../NamedRoutes";

import type {
    MenuToggleProps
} from "../types";

import
{
    AcquireAuthorization
} from "./Main";

import
{
    UserPermissions,
    permissionAllowed
} from "@ews/react-data";

import { useMyPermissions } from "../../Login/LoginProvider";


type SystemSidebarProps = MenuToggleProps & {
    routeParams?: RouteParams;
    title?: string;
};

const System: FC<SystemSidebarProps> = ({
    routeParams = {},
    onMenuToggle,
    title
}) =>
{
    const { translate: t } = useL10n();
    const { generate, params } = useNamedRoutes();
    const permissions: UserPermissions = useMyPermissions();

    title = title || t("Systemsettings");

    return (
        <Sidebar title={title} onMenuToggle={onMenuToggle}>
            <SidebarSection grow>
                <AcquireAuthorization systemId={params().id || ""} onMenuToggle={onMenuToggle} />
                {permissionAllowed(permissions.grantSystemAccess!) ? <><MenuLink
                    title={t('[SID_MNU_TAB_SETTINGS]')}
                    onClick={onMenuToggle}
                    link={generate('system:settings', routeParams)}
                    icon={options}
                />
                    <MenuSpacer /></> : <></>}
                <MenuLink
                    title={t('Show Plans')}
                    onClick={onMenuToggle}
                    icon={mapOutline}
                />
                <MenuLink
                    title={t('Maintenance report')} //[SID_SUB_MNU_STA_SERVICE_LIST] ?
                    onClick={onMenuToggle}
                    link={generate('system:maintenancereport', routeParams)}
                    icon={buildOutline}
                />
                <MenuLink
                    title={t('Event storage')} // [SID_MNU_TAB_EVENT_MEM] ? 
                    onClick={onMenuToggle}
                    link={generate('system:eventmemory', routeParams)}
                    icon={statsChartOutline}
                />
                <MenuLink
                    title={t('Show measurement')}
                    onClick={onMenuToggle}
                    link={generate('system:analoguevalues', routeParams)}
                    icon={thermometerOutline}
                />
                {/* <MenuLink
                    title={t('Show queries')}
                    onClick={onMenuToggle}
                    link={generate('system:queries', routeParams)}
                    icon={swapVerticalOutline}
                /> */}
                <MenuLink
                    title={t('Show shares')}
                    onClick={onMenuToggle}
                    link={generate('system:shares', routeParams)}
                    icon={keyOutline}
                />
                <MenuLink
                    title={t('Show phonenumbers')}
                    onClick={onMenuToggle}
                    link={generate('system:phonenumber', routeParams)}
                    icon={callOutline}
                />
                {/* <MenuSpacer />
                <MenuLink
                    title={t('Details on submission documents')}
                    link={generate('system:submissiondocuments', routeParams)}
                    onClick={onMenuToggle}
                    icon={documentTextOutline}
                />
                <MenuLink
                    title={t('Power packs for submission documents')}
                    link={generate('system:powerunitsDocumentation', routeParams)}
                    onClick={onMenuToggle}
                    icon={batteryChargingOutline}
                />
                <MenuLink
                    title={t('Show devices')}
                    link={generate('system:displaydevice', routeParams)}
                    onClick={onMenuToggle}
                    icon={desktopOutline}
                />
                <MenuLink
                    title={t('Other alarm devices')}
                    link={generate('system:otheralarmdevices', routeParams)}
                    onClick={onMenuToggle}
                    icon={megaphoneOutline}
                />
                <MenuLink
                    title={t('Other devices')}
                    link={generate('system:otherdevices', routeParams)}
                    onClick={onMenuToggle}
                    icon={terminalOutline}
                />
                <MenuLink
                    title={t('Inputs for submission documents')}
                    link={generate('system:inputssystemDocumentation', routeParams)}
                    onClick={onMenuToggle}
                    icon={enterOutline}
                />
                <MenuLink
                    title={t('Type definition submission documents')}
                    link={generate('system:typedefinitionsubmissiondocuments', routeParams)}
                    onClick={onMenuToggle}
                    icon={listOutline}
                />
                <MenuLink
                    title={t('Documentation history')}
                    link={generate('system:Documentationhistory', routeParams)}
                    onClick={onMenuToggle}
                    icon={serverOutline}
                /> */}
                {permissionAllowed(permissions.grantSystemAccess!) ? <><MenuSpacer />
                    <MenuLink
                        title={t('[SID_MNU_HK_DELETE]')}
                        onClick={onMenuToggle}
                        icon={trashOutline}
                    /></> : <></>}

            </SidebarSection>
        </Sidebar>
    );

};

export default System;