import
{
    useState
} from "react";

import
{
    SystemStatus
} from '@ews/react-data';

import
{
    useWebsocket
} from "./ReactData/ReactDataProvider";

import
{
    NetworkStatusIcon
} from './Icons';

export const NetworkStatus = () =>
{

    const provider = useWebsocket();
    const [status, setStatus] = useState<SystemStatus>(provider.getStatus());

    provider.on("status", setStatus);

    return <NetworkStatusIcon status={status} />;

};