//import { useL10n } from "@ews/react-localization-context";
import { Color } from "@ionic/core";
import { IonButton, IonFabButton, IonIcon } from "@ionic/react";
import { caretBackSharp, caretForwardSharp, playSkipBackSharp, playSkipForwardSharp, reload } from "ionicons/icons";
import React from "react";
import { Responsive } from "../../Responsive";
import SettingsSelect from "../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import paginationStyle from "./Pagination.module.scss";
import { pageRange } from "./pagerange";
import { PaginationProps } from "./types";

type ButtonProps = {
    onClick?: (page: number) => void;
    children?: React.ReactNode;
    value?: number;
    color?: Color;
    text?: string,
    textSlot?: "start" | "end";
    className?: HTMLIonButtonElement["className"];
    disabled?: HTMLIonButtonElement["disabled"];
};

const Button: React.FC<ButtonProps> = ({
    onClick,
    children,
    text,
    value,
    color,
    textSlot,
    className,
    disabled
}) => 
{
    return <IonButton
        disabled={disabled}
        className={className}
        color={color}
        onClick={() => { if (onClick && value) onClick(value); }}>
        {text && textSlot === "start" ? text : null}
        {children}{text}
        {text && textSlot === "end" ? text : null}
    </IonButton>;
};



const Pagination: React.FC<PaginationProps> = ({
    currentPage = 0,
    numberOfPages = 0,
    //totalCountOfItems = 0,
    pageSize = 10,
    text = "item per page",
    onPageChange,
    onPageSize,
    onReload
}) =>
{
    //const { translate: t } = useL10n();

    let startButtonCount = 1;

    if (currentPage === 1) startButtonCount = currentPage;
    else if (currentPage === numberOfPages) startButtonCount = currentPage - 2;
    else if (currentPage >= 2 && currentPage <= numberOfPages) startButtonCount = currentPage - 1;


    const buttons = Array.from({ length: 3 }, (_, i) => startButtonCount + i);

    return <>
        <div
            className={paginationStyle.paginationContainer}
        >

            <Responsive desktop>

                <div
                    className={paginationStyle.pageSizeContainer}
                >


                    <SettingsSelect
                        lines="none"
                        value={pageSize.toString()}
                        text={text}
                        onSelect={(value) =>
                        {
                            const pageSize = Number(value);
                            if (onPageSize) onPageSize(pageSize);
                        }}
                    >
                        {pageRange.map(size =>
                        {
                            const value = size.toString();

                            return <SettingsSelectOption key={size} text={size === Infinity ? "all" : value} value={value} />;
                        })}
                    </SettingsSelect>

                </div>

            </Responsive>

            <Button
                disabled={currentPage <= 1}
                value={1}
                color={"secondary"}
                onClick={(page) =>
                {
                    if (onPageChange) onPageChange(page);
                }}
            >
                <IonIcon icon={playSkipBackSharp}></IonIcon>
            </Button>
            <Button
                disabled={currentPage <= 1}
                value={currentPage - 1}
                color={"secondary"}
                onClick={(page) =>
                {
                    if (page >= 1) {
                        if (onPageChange) onPageChange(page);
                    };
                }}
            >
                <IonIcon icon={caretBackSharp}></IonIcon>
            </Button>

            {numberOfPages > 0 ? buttons.map((_, i) =>
            {
                const value = startButtonCount + i;

                if (value <= 0 || value > numberOfPages) return null;

                return <Button
                    key={i}
                    value={value}
                    text={`${value}`}
                    onClick={(page) =>
                    {
                        if (onPageChange) onPageChange(page);
                    }}
                    color={value === currentPage ? "primary" : "secondary"}
                />;
            }) : null
            }

            <Button
                disabled={currentPage >= numberOfPages}
                value={currentPage + 1}
                color={"secondary"}
                onClick={(page) =>
                {
                    if (page <= numberOfPages) {
                        if (onPageChange) onPageChange(page);
                    };
                }}
            >
                <IonIcon icon={caretForwardSharp}></IonIcon>
            </Button>

            <Button
                disabled={currentPage >= numberOfPages}
                value={numberOfPages}
                color={"secondary"}
                onClick={(page) =>
                {
                    if (onPageChange) onPageChange(page);
                }}
            >
                <IonIcon icon={playSkipForwardSharp}></IonIcon>
            </Button>
        </div >
    </>;

};

export default Pagination;;