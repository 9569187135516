import { useL10n } from "@ews/react-localization-context";
import
{
    IonLabel
} from "@ionic/react";
import React, { useState } from "react";
import { useModifyUserNotifications, useUser, useUserNotifications } from "../../../ReactData/user";
import { useIsMobile } from "../../../Responsive";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsTableRow from "../../../Settings/SettingsComponents/SettingsTable/SettingsRow/SettingsTableRow";
import SettingsTableColumn from "../../../Settings/SettingsComponents/SettingsTable/SettingsTableColumn/SettingsTableColumn";
import SettingsTableGrid from "../../../Settings/SettingsComponents/SettingsTable/SettingsTableGrid/SettingsTableGrid";
import PushOption from "./Components/Options/Options";

import { PushSettingsProps } from "./types";
import
{
    UserNotifications,
    NotificationChannel
} from "@ews/react-data";

const PushSettings: React.FC<PushSettingsProps> = ({ id }) =>
{
    const user = useUser(id);
    const userNotification = useUserNotifications(id);

    const modifyNotification = useModifyUserNotifications();

    const { translate: t } = useL10n();
    const mobileView = useIsMobile();

    const [speechToText, setSpeechToText] = useState<boolean>(false);

    //const columnMargin = { margin: '5px 0' };
    const updateNotification = async (data: UserNotifications) =>
    {
        await modifyNotification(user.id!, data);
    };
    const titlePosition = mobileView ? { textAlign: 'center' } : { textAlign: 'left', verticalAlign: 'sub' };
    const childPosition = { marginLeft: '20px', fontStyle: 'italic', fontSize: 'medium !important' };

    console.log(NotificationChannel);
    const sizeMainCol = mobileView ? "12" : "6";
    const sizeSoundCol = "2";
    const otherCols = "1";

    return <>
        <SettingsCol size={sizeMainCol} xl={sizeMainCol} md={sizeMainCol} lg={sizeMainCol}>

            <SettingsTableGrid>
                <SettingsTableRow hidden={mobileView}>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('event')}:`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn size={otherCols}>
                        <IonLabel style={{ textAlign: "center", margin: 0 }}>{`${t('begin')}:`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn size={otherCols}>
                        <IonLabel style={{ textAlign: "center", margin: 0 }}>{`${t('end')}:`}</IonLabel>
                    </SettingsTableColumn>
                    {/* <SettingsTableColumn size={otherCols}>
                        <IonLabel style={{ textAlign: "center", margin: 0 }}>{`${t('priority')}:`}</IonLabel>
                    </SettingsTableColumn> */}
                    <SettingsTableColumn size={sizeSoundCol}>
                        <IonLabel style={{ textAlign: "center", margin: 0 }}>{`${t('sound')}:`}</IonLabel>
                    </SettingsTableColumn>

                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_HK_FIRE_ALARMS]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.fireAlarm || 0}
                        sound={userNotification.fireAlarmSound}
                        onChange={(fireAlarm) => updateNotification({ fireAlarm } as UserNotifications)}
                        onSelect={(fireAlarmSound) => updateNotification({ fireAlarmSound: `${fireAlarmSound}` } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow>

                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_DEV_STATE_PREALARM]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.preAlarm || 0}
                        sound={userNotification.preAlarmSound}
                        onChange={(preAlarm) => updateNotification({ preAlarm } as UserNotifications)}
                        onSelect={(preAlarmSound) => updateNotification({ preAlarmSound: `${preAlarmSound}` } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow>

                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_EV_FIRE_PREALARM_SH]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.fireAlert || 0}
                        sound={userNotification.fireAlertSound}
                        onChange={(fireAlert) => updateNotification({ fireAlert } as UserNotifications)}
                        onSelect={(fireAlertSound) => updateNotification({ fireAlertSound: `${fireAlertSound}` } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow>

                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_EV_FIRE_TEST_ALARM_SH]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.testScenario || 0}

                        onChange={(testScenario) =>
                        {

                            updateNotification({ testScenario } as UserNotifications);
                        }}
                    //onSelect={(testAlarmSound) => updateNotification({ testAlarmSound })}
                    />
                </SettingsTableRow>


                <SettingsTableRow>
                    <PushOption
                        style={mobileView ? titlePosition : childPosition}
                        center={false}
                        label="[SID_EV_TECHN_MESS_TEST_COND_SH]"
                        size={otherCols}
                        disabled={!(userNotification.testScenario & NotificationChannel.PUSH || userNotification.testScenario & NotificationChannel.PUSH_END)}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.testCondition || 0}

                        onChange={(testCondition) =>
                        {

                            updateNotification({ testCondition } as UserNotifications);
                        }}
                    />
                </SettingsTableRow>



                <SettingsTableRow
                //hidden={ }
                >

                    <PushOption
                        style={mobileView ? titlePosition : childPosition}
                        center={false}
                        label="[SID_EV_FIRE_TEST_ALARM_SH]"
                        size={otherCols}
                        disabled={!(userNotification.testScenario & NotificationChannel.PUSH || userNotification.testScenario & NotificationChannel.PUSH_END)}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.testAlarm || 0}

                        onChange={(testAlarm) =>
                        {

                            updateNotification({ testAlarm } as UserNotifications);
                        }}
                    />

                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={mobileView ? titlePosition : childPosition}
                        center={false}
                        label="[SID_DEV_STATE_TEST_ACTIVE]"
                        size={otherCols}
                        disabled={!(userNotification.testScenario & NotificationChannel.PUSH || userNotification.testScenario & NotificationChannel.PUSH_END)}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.testActivation || 0}

                        onChange={(testActivation) =>
                        {

                            updateNotification({ testActivation } as UserNotifications);
                        }}
                    />
                </SettingsTableRow>

                <SettingsTableRow >
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_TAB_ACT_OUTP]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.activeOutput || 0}
                        sound={userNotification.activeOutputSound}
                        onChange={(activeOutput) => updateNotification({ activeOutput } as UserNotifications)}
                        onSelect={(activeOutputSound) => updateNotification({ activeOutputSound: `${activeOutputSound}` } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={mobileView ? titlePosition : childPosition}
                        center={false}
                        label="Silenct actuations"
                        size={otherCols}
                        disabled={!(userNotification.activeOutput & NotificationChannel.PUSH || userNotification.activeOutput & NotificationChannel.PUSH_END)}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.activeOutputSilent || 0}
                        onChange={(activeOutputSilent) => updateNotification({ activeOutputSilent } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_TAB_FAULTS]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.fault || 0}
                        sound={userNotification.faultSound}
                        onChange={(fault) => updateNotification({ fault } as UserNotifications)}
                        onSelect={(faultSound) => updateNotification({ faultSound: `${faultSound}` } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow >
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_EV_TECHN_MESS_ALARM]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.technicalMessage || 0}
                        sound={userNotification.technicalMessageSound}
                        onChange={(technicalMessage) => updateNotification({ technicalMessage } as UserNotifications)}
                        onSelect={(technicalMessageSound) => updateNotification({ technicalMessageSound: `${technicalMessageSound}` } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow>


                    <PushOption
                        style={mobileView ? titlePosition : childPosition}
                        center={false}
                        label="Silent techn. msg."
                        size={otherCols}
                        disabled={!(userNotification.technicalMessage & NotificationChannel.PUSH || userNotification.technicalMessage & NotificationChannel.PUSH_END)}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.technicalMessageSilent || 0}
                        onChange={(technicalMessageSilent) => updateNotification({ technicalMessageSilent } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow>


                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_TAB_DISABLEMENTS]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.disablement || 0}
                        sound={userNotification.disablementSound}
                        onChange={(disablement) => updateNotification({ disablement } as UserNotifications)}
                        onSelect={(disablementSound) => updateNotification({ disablementSound: `${disablementSound}` } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_TAB_EXT_EV]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.extinguishingEvent || 0}
                        sound={userNotification.extinguishingEventSound}
                        onChange={(extinguishingEvent) => updateNotification({ extinguishingEvent } as UserNotifications)}
                        onSelect={(extinguishingEventSound) => updateNotification({ extinguishingEventSound: `${extinguishingEventSound}` } as UserNotifications)}
                    />
                </SettingsTableRow>

                <SettingsTableRow>

                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_TAB_STATUS]"
                        size={otherCols}
                        options={{
                            push: "begin",
                            push_end: "end",
                            //prioritat: "priorität"
                        }}
                        value={userNotification.status || 0}
                        sound={userNotification.statusSound}
                        onChange={(status) => updateNotification({ status } as UserNotifications)}
                        onSelect={(statusSound) => updateNotification({ statusSound: `${statusSound}` } as UserNotifications)}
                    />
                </SettingsTableRow>
            </SettingsTableGrid>



        </SettingsCol >

        <SettingsCol>
            <SettingsCheckbox text={"Text to speach"} checked={speechToText} onChange={(checked) =>
            {
                setSpeechToText(checked);

            }}>

                <SettingsCheckbox text={"System name"} checked={false} onChange={(checked) => { console.log(checked); }} />
                <SettingsCheckbox text={"Logical number"} checked={false} onChange={(checked) => { console.log(checked); }} />
                <SettingsCheckbox text={"Event text"} checked={false} onChange={(checked) => { console.log(checked); }} />
                <SettingsCheckbox text={"Event time"} checked={false} onChange={(checked) => { console.log(checked); }} />
                <SettingsCheckbox text={"Zone text"} checked={false} onChange={(checked) => { console.log(checked); }} />
                <SettingsCheckbox text={"Element text"} checked={false} onChange={(checked) => { console.log(checked); }} />

            </SettingsCheckbox>

        </SettingsCol>
        {/* 
        <SettingsCol>

                        <SettingsSelect startText="Tone at " text={"Fire alarm"} value={fireAlarm.option} onSelect={(value) => fireAlarm.option = value}>
                            <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                            <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                            <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                            <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                            <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                            <SettingsSelectOption text={"none"} value={"none"} />
                        </SettingsSelect>

            <SettingsSelect startText="Tone at " text={"Pre-alarm"} value={preAlarm.option} onSelect={(value) => preAlarm.option = value}>
                <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                <SettingsSelectOption text={"none"} value={"none"} />
            </SettingsSelect>

            <SettingsSelect startText="Tone at " text={"Fire pre-warning"} value={firePreWarning.option} onSelect={(value) => firePreWarning.option = value}>
                <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                <SettingsSelectOption text={"none"} value={"none"} />
            </SettingsSelect>

            <SettingsSelect startText="Tone at " text={"Test alarm"} value={mainTestAlarm.option} onSelect={(value) => mainTestAlarm.option = value}>
                <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                <SettingsSelectOption text={"none"} value={"none"} />
            </SettingsSelect>

            <SettingsSelect startText="Tone at " text={"Active output"} value={activeOutput.option} onSelect={(value) => activeOutput.option = value}>
                <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                <SettingsSelectOption text={"none"} value={"none"} />
            </SettingsSelect>

            <SettingsSelect startText="Tone at " text={"Fault"} value={fault.option} onSelect={(value) => fault.option = value}>
                <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                <SettingsSelectOption text={"none"} value={"none"} />
            </SettingsSelect>

            <SettingsSelect startText="Tone at " text={"Technical message"} value={technicalMessage.option} onSelect={(value) => technicalMessage.option = value}>
                <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                <SettingsSelectOption text={"none"} value={"none"} />
            </SettingsSelect>

            <SettingsSelect startText="Tone at " text={"Disablement"} value={disablement.option} onSelect={(value) => disablement.option = value}>
                <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                <SettingsSelectOption text={"none"} value={"none"} />
            </SettingsSelect>

            <SettingsSelect startText="Tone at " text={"Extinguishing event"} value={extinguishingEvent.option} onSelect={(value) => extinguishingEvent.option = value}>
                <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                <SettingsSelectOption text={"none"} value={"none"} />
            </SettingsSelect>

            <SettingsSelect startText="Tone at " text={"Online status"} value={online.option} onSelect={(value) => online.option = value}>
                <SettingsSelectOption text={"Standard tone of end device"} value={"default"} />
                <SettingsSelectOption text={"Fire 1 (sawtooth)"} value={"sawtooth"} />
                <SettingsSelectOption text={"Fire 2 (beep)"} value={"beep"} />
                <SettingsSelectOption text={"Fire 3 (triple beep)"} value={"triple beep"} />
                <SettingsSelectOption text={"Fire 4 (bell)"} value={"bell"} />
                <SettingsSelectOption text={"none"} value={"none"} />
            </SettingsSelect>

        </SettingsCol> */}
    </>;
};

export default PushSettings;