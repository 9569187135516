import { useL10n } from "@ews/react-localization-context";
import { IonButton, IonCheckbox, IonHeader, IonLabel, IonList, IonModal, IonTitle, IonToolbar, useIonRouter } from "@ionic/react";
import React, { useRef, useState } from "react";
import { useNamedRoutes } from "../../NamedRoutes";

import
{
    User
} from '@ews/react-data';

import
{
    useCreateUser
} from "../../ReactData/user";

import { useIsMobile } from "../../Responsive";
import SettingsTextInput from "../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import modalStyle from "./NewUserModal.module.scss";
import { NewUserModalProps } from "./types";
import { IonModalCustomEvent, OverlayEventDetail } from "@ionic/core";
import CustomerTypeAhead from "../../Customer/CustomerTypeAhead";


const NewUserModal: React.FC<NewUserModalProps> = ({ trigger, onDismiss, customerId }) =>
{
    const defaultUser: User = {
        customerId,
        active: true
    } as User;

    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    const modal = useRef<HTMLIonModalElement>(null);

    const [user, setUser] = useState<User>(defaultUser as User);//(null);
    const { generate } = useNamedRoutes();
    const router = useIonRouter();
    const createUser = useCreateUser();

    async function confirm(user: User)
    {
        const { email } = user;

        if (!email) return;
        //const newUser = { username, email, password, ...defaultUser };
        try {

            const createdUser = await createUser(user);

            const id = createdUser.id;
            modal.current?.dismiss();

            const link = generate('user', { id });
            router.push(link);

        } catch (err) {
            console.log(err);
        }
    }

    function onWillDismiss(event: IonModalCustomEvent<OverlayEventDetail<any>>)
    {
        setUser(defaultUser);
        onDismiss && onDismiss(event);
    }

    return <IonModal
        ref={modal}
        className={modalStyle.newSystemModal}
        trigger={trigger}
        breakpoints={mobileView ? [.6] : undefined}
        onWillDismiss={onWillDismiss}>
        <IonHeader>
            <IonToolbar>
                <div className={`${modalStyle.titleCenter}`}>
                    <IonTitle>{`${t("new User")}`}</IonTitle>
                </div>
            </IonToolbar>
        </IonHeader>
        <IonList
            className="ion-padding"
            color="primary">

            <CustomerTypeAhead
                label="Customer"
                currentId={customerId || ""}
                onChange={(customerId) => setUser({ ...user, customerId } as User)}
            />

            <SettingsTextInput
                inputType="email"
                text={"email"}
                value={user.email}
                onChange={(email) =>
                {
                    setUser({ ...user, email } as User);
                }} />
            <IonCheckbox 
                className="ion-padding" 
                labelPlacement="end"
                checked={user.active} 
                onIonChange={(e) => setUser({...user, active: e.detail.checked} as User)}
                >
                User Active
                </IonCheckbox>

        </IonList>
        <div className={`${modalStyle.buttonContainer} ${modalStyle.marginBottom}`}>
            <IonButton
                slot="start"
                color={"danger"}
                onClick={() => modal.current?.dismiss()}>{t("Cancel")}</IonButton>
            <IonButton
                disabled={!("email" in user)}
                slot="end"
                color={"success"}
                onClick={() => confirm(user)}>
                {t("Create")}
            </IonButton>
        </div>
    </IonModal>;

};


export default NewUserModal;