import
{
    PaginatedResult,
    PaginationRequest,
    User
} from "@ews/react-data";

import { IonCol, IonIcon } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import FilterList from "../FilterList/FilterList";
import List from "../List/List";
import { pageRange } from "../List/Pagination/pagerange";
import { useMyDateFormat } from "../Login/LoginProvider";
import Sidebar from "../Navigation/Sidebar/User";
import { useFetchUserList } from "../ReactData/user";
import { useIsMobile } from "../Responsive";
import SettingsSelect from "../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { Filter } from "../System/Reports/types/reportTypes";
import { UsersListProps } from "./type";
import { checkmark, remove, skull } from "ionicons/icons";

type ListFilter = {
    userName?: string;
    customerId?: string;
    city?: string;
    searchOperator?: "OR";
};

const standardFilter: Filter<ListFilter> = {
    sortCriteria: "username",
    sortOrder: "ASC",
    //searchOperator: "OR"
};


const UserList: React.FC<UsersListProps> = ({
    customerId,
    isFilterOpen,
    onIsOpen
}) =>
{

    const filterDebounceTime = 900;
    const mobileView = useIsMobile();
    const dateFormat = useMyDateFormat();

    const [currentUsers, setCurrentUsers] = useState<User[]>([]);

    const page = useRef<number | undefined>(0);
    const numberOfPages = useRef<number | undefined>(10);
    const numberOfItemsPerPage = useRef(pageRange[1]);

    const filter = useFetchUserList();

    const landValue = useRef('');

    const [currentState, setState] = useState<PaginationRequest & ListFilter>(standardFilter);

    if (customerId) {
        currentState.customerId = customerId;

    }

    async function handleFilterResponse(filterCriteria: PaginationRequest & ListFilter)
    {
        const response: PaginatedResult<User> = await filter(filterCriteria) as PaginatedResult<User>;

        const { currentPage, pageSize, results, totalCountOfPages } = response;

        page.current = currentPage;
        numberOfPages.current = totalCountOfPages;
        numberOfItemsPerPage.current = pageSize;

        setCurrentUsers(results);
    }
    useEffect(() =>
    {
        handleFilterResponse(currentState);
    }, [currentState]);


    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <FilterList
            isDetailsHidden={false}
            onIsOpen={onIsOpen}
            isOpen={isFilterOpen}
            //showButton={false}
            //onDetailOpen={setFilterDetailsOpen}
            onReset={() => setState(standardFilter)}

            details={<>
                <IonCol>

                    <SettingsTextInput
                        id="username"
                        text="Name"
                        debounce={filterDebounceTime}
                        value={currentState.userName}

                        onChange={(userName) =>
                        {
                            const currentFilter = { ...currentState };

                            if (userName.length) currentFilter.userName = userName;
                            else delete currentFilter.userName;

                            setState(currentFilter);
                        }}
                    />

                </IonCol>
                <IonCol>
                    <SettingsTextInput
                        id="email"
                        text="Email"
                        debounce={filterDebounceTime}
                        value={currentState.email}
                        onChange={(email) =>
                        {
                            const currentFilter = { ...currentState };

                            if (email.length) currentFilter.email = email;
                            else delete currentFilter.email;

                            setState(currentFilter);
                        }}
                    />


                </IonCol>
                <IonCol></IonCol>
            </>} >
        </FilterList >

        < List
            rows={currentUsers}

            sort={currentState.sortCriteria ? {
                key: currentState.sortCriteria,
                direction: currentState.sortOrder || "ASC"
            } : undefined}

            onSort={(sortCriteria, sortOrder) => setState({ ...currentState, sortCriteria, sortOrder })}
            onPageChange={(page) => setState({ ...currentState, page })}

            currentPage={page.current}
            numberOfPages={numberOfPages.current}

            /* maxHeight={mobileView ? undefined : '50vh'} */
            link={{ url: "user", paramsKey: "id" }}
            onMenu={(id: string, onMenuToggle: () => void) => <Sidebar onMenuToggle={onMenuToggle} routeParams={{ id }} />}
            headers={
                [

                    {
                        title: 'User-ID',
                        key: 'username',
                        breakpoint: 0, up: true,
                    },
                    {
                        title: 'First name',
                        key: 'firstName',
                        breakpoint: 0, up: true,
                    },
                    {
                        title: 'Last name',
                        key: 'lastName',
                        breakpoint: 0, up: true,
                    },
                    {
                        title: 'Last login',
                        key: 'lastLogin',
                        breakpoint: 0, up: true,
                        child: (row) => <>{row.lastLogin ? dateFormat(new Date(row.lastLogin)) : ""}</>
                    },
                    {
                        title: 'Email',
                        key: 'email',
                        breakpoint: 0, up: true,
                    },
                    {
                        title: 'Active',
                        key: 'active',
                        breakpoint: 0, up: true,
                        child: (row: any) =>
                        {
                            return row.active ? <IonIcon icon={checkmark} /> : <IonIcon icon={skull} />;
                        }
                    },

                    /*     {
            title: 'Post code',
        key: 'plz',
        breakpoint: 1500, up: true,
                        },
        {
            title: 'Land',
        key: 'land',
        breakpoint: 1500, up: true,
                        },
        {
            title: 'City',
        key: 'city',
        both: true,
                        }, */
                ]}

        />

    </div>;
};

export default UserList;

