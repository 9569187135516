import { useL10n } from "@ews/react-localization-context";
import
{
    options, powerOutline, trashOutline
} from "ionicons/icons";

import { FC } from "react";
import
{
    MenuLink,
    MenuSpacer,
    Sidebar,
    SidebarSection
} from "./Sidebar";

import
{
    RouteParams,
    useNamedRoutes
} from "../../NamedRoutes";

import type {
    MenuToggleProps
} from "../types";

import
{
    AcquireAuthorization
} from "./Main";

import
{
    useMyPermissions
} from "../../Login/LoginProvider";

import
{
    UserPermissions,
    permissionAllowed
} from "@ews/react-data";


type SystemSidebarProps = MenuToggleProps & {
    routeParams?: RouteParams;
    title?: string;
};

const User: FC<SystemSidebarProps> = ({
    routeParams = {},
    onMenuToggle,
    title
}) =>
{
    const { translate: t } = useL10n();
    const { generate } = useNamedRoutes();
    const permissions: UserPermissions = useMyPermissions();

    title = title || t('Usersettings');

    return (
        <Sidebar title={title} onMenuToggle={onMenuToggle}>
            <SidebarSection grow>
                {/* <AcquireAuthorization onMenuToggle={onMenuToggle} /> */}
                {permissionAllowed(permissions.createUser!) ? <MenuLink
                    title={t('[SID_MNU_TAB_SETTINGS]')}
                    onClick={onMenuToggle}
                    link={generate('user', routeParams)}
                    icon={options}
                /> : <></>}

                <MenuLink
                    title={t('Simulate Login')}
                    onClick={onMenuToggle}
                    icon={powerOutline}
                />

                {permissionAllowed(permissions.createUser!) ? <>
                    <MenuSpacer />
                    <MenuLink
                        title={t('[SID_MNU_HK_DELETE]')}
                        onClick={onMenuToggle}
                        icon={trashOutline}
                    /></> : <></>}
            </SidebarSection>
        </Sidebar>
    );

};

export default User;