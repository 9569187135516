import
{
    useModifySystemShareNotifications,
    useSystemNotifications,
    useSystemShareNotifications
} from '../../../../../../../ReactData/system';

import
{
    useUserNotifications
} from '../../../../../../../ReactData/user';

import
{
    Props
} from './types';

import
{
    SystemShareNotifications,
    NotificationChannel
} from '@ews/react-data';

import SettingsCol from '../../../../../../../Settings/SettingsCol/SettingsCol';
import NotificationTable from '../components/NotificationsTable/NotificationsTable';
import NumberRange from '../components/NumberRange';


const Push: React.FC<Props> = ({ systemId, userId }) =>
{
    const notifications = useSystemShareNotifications(systemId || "", userId!);
    const userNotifications = useUserNotifications(userId || "");
    const systemNotifications = useSystemNotifications(systemId || "");

    const modify = useModifySystemShareNotifications();
    const modifyData = async (data: SystemShareNotifications) => await modify(systemId!, userId!, data);

    const setFlag = (key: keyof SystemShareNotifications, type: keyof typeof NotificationChannel, value: boolean) =>
    {
        if (value) {
            (notifications[key] as NotificationChannel) = (notifications[key] as NotificationChannel) | NotificationChannel[type];
        } else {
            (notifications[key] as NotificationChannel) = (notifications[key] as NotificationChannel) & ~NotificationChannel[type];
        }
        return notifications;

    };

    return <>

        <SettingsCol>
            <NotificationTable
                notification={notifications}
                title='Share'
                onCheck={(v) =>
                {
                    modifyData(setFlag(v.key, v.type, v.value));
                }}
            />
        </SettingsCol >
        <SettingsCol>
            <NotificationTable
                notification={systemNotifications}
                title='System'
                disabled
                onCheck={(v) => console.log(v)}
            />
        </SettingsCol >
        <SettingsCol>
            <NotificationTable
                notification={userNotifications}
                title='User'
                disabled
                onCheck={(v) => console.log(v)}
            />
        </SettingsCol >
        <SettingsCol>

            <NumberRange
                label='Zone'
                from={notifications.fromZone}
                to={notifications.toZone}
                onChange={(fromZone, toZone) => modifyData({ fromZone, toZone } as SystemShareNotifications)}
            />

            <NumberRange
                label='Action'
                from={notifications.fromActuation}
                to={notifications.toActuation}
                onChange={(fromActuation, toActuation) => modifyData({ fromActuation, toActuation } as SystemShareNotifications)}
            />

            <NumberRange
                label='AlarmingDevice'
                from={notifications.fromAlarmingDevice}
                to={notifications.toAlarmingDevice}
                onChange={(fromAlarmingDevice, toAlarmingDevice) => modifyData({ fromAlarmingDevice, toAlarmingDevice } as SystemShareNotifications)}
            />
            <NumberRange
                label='TransmissionDevice'
                from={notifications.fromTransmissionDevice}
                to={notifications.toTransmissionDevice}
                onChange={(fromTransmissionDevice, toTransmissionDevice) => modifyData({ fromTransmissionDevice, toTransmissionDevice } as SystemShareNotifications)}
            />
        </SettingsCol >
    </>;
};
export default Push;
