import { CustomerDetails } from "@ews/react-data";
import { useNamedRoutes } from "../../../NamedRoutes";

import
{
    useCustomerDetails,
    useModifyCustomerDetails
} from "../../../ReactData/customer";

import { IonAvatar } from "@ionic/react";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsDropFile from "../../../Settings/SettingsComponents/SettingsDropFile/SettingsDropFile";
import SettingsInfo from "../../../Settings/SettingsComponents/SettingsInfo/SettingsInfo";
import logoStyle from "./logoSettings.module.scss";
import { LogoSettingsProps } from "./types";

const LogoSettings: React.FC<LogoSettingsProps> = ({ customer, }) =>
{
    const { params } = useNamedRoutes();
    const { id } = params();

    const logo = useCustomerDetails(customer.id || id!);
    const modifyCustomerDetails = useModifyCustomerDetails();

    function saveCustomerDetails(key: keyof CustomerDetails, value: any)
    {
        if (!customer.id || !(key in logo)) return;
        modifyCustomerDetails(customer.id, { [key]: value } as CustomerDetails);
    }

    return <>
        <SettingsCol>

            <SettingsDropFile
                disabled
                text={"Customer logo"}
                onFile={(formData) =>
                {
                }} />
            <SettingsCheckbox
                text={"Use customer logo"}
                checked={logo.useCustomerLogo || false}
                onChange={(checked) => { saveCustomerDetails("useCustomerLogo", checked); }} />

            <SettingsInfo
                text={"The customer logo should not exceed a picture height fo 50 pixels and a pucture width of 200 pixels. PNG is supported as file format. This customer logl is currently stored an the server"}
                value={""} />
            <SettingsInfo
                text={"This customer logo is currently stored an the server"}
                value={logo.customerLogoUrl || ""} >
                {logo.customerLogoUrl ? <div className={logoStyle.img}>
                    <IonAvatar>
                        <img src={logo.customerLogoUrl} alt="" />
                    </IonAvatar>
                </div>
                    :
                    null}
            </SettingsInfo>


        </SettingsCol>
        <SettingsCol>

        </SettingsCol>
        <SettingsCol>

        </SettingsCol>

    </>;
};

export default LogoSettings;