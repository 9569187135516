import { useL10n } from "@ews/react-localization-context";
import { PaginationRequest } from "@ews/react-data";
import { FilterCategory, eventDetails, formatLogicalAddress, slotIdentifier, timestampToDate, deviceType } from "@ews/zlt-events";
import { IonCheckbox, IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import { useCallback, useEffect, useRef, useState } from "react";
import FilterList from "../../../FilterList/FilterList";
import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";
import { PaginatedResult, useFetchSystemEvents } from "../../../ReactData/system";
import { useIsMobile } from "../../../Responsive";
import DatePicker from "../../../Settings/SettingsComponents/SettingsRangeInput/components/DatePicker/DatePicker";
import SettingsZoneRangeInput from "../../../Settings/SettingsComponents/SettingsRangeInput/components/Zone/SettingsZoneRangeInput";
import { Filter } from "../types/reportTypes";
import { allEventsKindType, filterEventList } from "./helper";

import
{
    EventFilterKeys,
    EventMemoryProps
} from "./types";

import { SystemEvents } from "@ews/react-data";
import eventStyle from "./EventMemory.module.scss";

const EventMemory: React.FC<EventMemoryProps> = ({
    system,
    isFilterOpen,
    onIsOpen
}) =>
{
    const { translate: t, formatDate } = useL10n();

    const filterData = useFetchSystemEvents();
    const mobileView = useIsMobile();

    const [systemEvents, setSystemEvents] = useState<SystemEvents[]>([]);
    const [clearFilterInputs/*, setClearFilterInputs*/] = useState(false);

    //const filterDetailsOpen = false;
    const page = useRef<number | undefined>();
    const numberOfPages = useRef<number | undefined>();
    const numberOfItemsPerPage = useRef(pageRange[1]);
    //const [hideDetailsFilter, setHideDetailsFilter] = useState(true);

    const standardFilter: Filter<EventFilterKeys> = {
        "eventDetails.filter": allEventsKindType,
        sortCriteria: "runningNumber",
        sortOrder: "ASC"
    };

    const [filterEvents, setFilterEvents] = useState<Filter<EventFilterKeys>>(standardFilter);


    const handleSelectEvent = (eventKindType: number, checked: boolean) =>
    {
        const selectedEvent = filterEvents["eventDetails.filter"] ? filterEvents["eventDetails.filter"] : 0b00;

        const currentFilter = { ...filterEvents };

        if (eventKindType === 0) delete currentFilter!["eventDetails.filter"];
        else currentFilter!["eventDetails.filter"] = checked ? eventKindType | selectedEvent : eventKindType ^ selectedEvent;

        //delete currentFilter.page;

        setFilterEvents({ ...currentFilter });
    };

    // function resetFilter()
    // {
    //     numberOfItemsPerPage.current = pageRange[1];
    //     setFilterEvents(standardFilter);
    // }

    const getFilteredData = useCallback(async (id: string, filter: PaginationRequest) =>
    {
        const response = await filterData(id, filter);
        if (!response) return;

        const { results, currentPage, pageSize, totalCountOfPages } = response as PaginatedResult<SystemEvents>;

        page.current = currentPage;
        numberOfPages.current = totalCountOfPages;
        numberOfItemsPerPage.current = pageSize;

        setSystemEvents(results);
    }, [filterData]);

    useEffect(() =>
    {
        if (system.id) getFilteredData(system.id, filterEvents);
    }, [filterEvents, system/* , getFilteredData */]);


    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <FilterList
            isDetailsHidden={false}
            onReset={() => setFilterEvents({})}
            //onDetailOpen={(state) => setHideDetailsFilter(state)}
            onIsOpen={onIsOpen}
            isOpen={isFilterOpen}
            details={<>
                <IonCol>
                    <IonGrid style={{ overflowY: "auto" }}>
                        <IonRow>
                            <IonCol style={{ border: "0" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <IonLabel style={{ maxWidth: "fit-content" }}>{t("Filter")}</IonLabel>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>

                            {filterEventList.map((eventKindType, index) =>
                            {

                                let isChecked = false;
                                if (eventKindType !== allEventsKindType && filterEvents["eventDetails.filter"]) {
                                    let byte = eventKindType & filterEvents["eventDetails.filter"];
                                    isChecked = Boolean(byte);
                                }
                                else {
                                    isChecked = filterEvents["eventDetails.filter"] === allEventsKindType;
                                }
                                const width = mobileView ? { minWidth: "100%" } : { width: "fit-content" };

                                return (
                                    <IonCol
                                        sizeLg="4"
                                        sizeMd="1"
                                        sizeSm="6"
                                        style={{ display: "flex", justifyContent: "start", alineItem: "center", border: "0", paddingStart: index === 0 ? "5px" : "", ...width }}
                                        key={`${index}`} >

                                        <IonCheckbox
                                            style={{ margin: 0, padding: 0, width: "fit-content" }}
                                            checked={isChecked}
                                            onIonChange={({ detail }) =>
                                            {
                                                handleSelectEvent(eventKindType, detail.checked);
                                            }}>
                                            {" "}
                                        </IonCheckbox>
                                        <IonLabel style={{ margin: "0 10px", verticalAlign: "middle" }}>{eventKindType === allEventsKindType ? t("FC_All") : t(`FC_${FilterCategory[eventKindType]}`)}</IonLabel>
                                    </IonCol>
                                );
                            }
                            )}
                        </IonRow>
                    </IonGrid>
                    <IonCol style={{ border: "0" }} ></IonCol>
                </IonCol>
                <IonCol sizeLg="3" sizeMd="1" sizeSm="1">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonLabel style={{ maxWidth: "fit-content" }}>{t("Date")}</IonLabel>
                    </div>
                    <DatePicker
                        className={eventStyle.dateTime}
                        childText={{ start: "start", end: "end" }}
                        value={{}}
                        divider={false}
                        position="row"
                        onChange={(range) =>
                        {
                            const startDate = range.from;
                            const endDate = range.to;
                            const currentFilter = { ...filterEvents };

                            currentFilter.timestamp = `${startDate},${endDate}`;
                            setFilterEvents({ ...currentFilter });
                        }}

                    />
                </IonCol>

                <IonCol sizeLg="3" sizeMd="1" sizeSm="1">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonLabel style={{ maxWidth: "fit-content" }}>{t("Element")}</IonLabel>
                    </div>
                    <SettingsZoneRangeInput
                        lines="none"
                        divider={false}
                        labels={{ start: "start", end: "end" }}
                        inputLabels={{ parent: "Nummer", children: "Element" }}
                        position={{ parent: "row", children: "line" }}
                        reset={clearFilterInputs}
                        onChange={(value) => 
                        {
                            if (filterEvents.page) delete filterEvents.page;
                            const currentFilter = { ...filterEvents };

                            currentFilter.zone = `${value.startZoneElement}`;

                            if (value.endZoneElement) {
                                currentFilter.zone = `${value.startZoneElement},${value.endZoneElement}`;
                            }

                            setFilterEvents({ ...currentFilter });
                        }} />

                </IonCol>
            </>} >
        </FilterList >


        < List
            rows={systemEvents}
            sort={filterEvents.sortCriteria ? {
                key: filterEvents.sortCriteria,
                direction: filterEvents.sortOrder || "ASC"
            } : undefined}

            onSort={(sortCriteria, sortOrder) => setFilterEvents({ ...filterEvents, sortCriteria, sortOrder })}
            onPageChange={(page) => setFilterEvents({ ...filterEvents, page })}

            pageSize={filterEvents.pageSize}
            onPageSize={(pageSize) => setFilterEvents({ ...filterEvents, pageSize })}

            onReload={() =>
            {
                if (system.id) getFilteredData(system.id, filterEvents);
            }}

            currentPage={page.current}
            numberOfPages={numberOfPages.current}
            maxHeight={mobileView ? "86vh" : "47vh"}
            noLink
            //link={{ url: "system:events", paramsKey: "id" }}
            headers={[
                { title: 'ID', key: 'runningNumber', breakpoint: 1200, up: true, className: `${eventStyle["colSize"]} ${eventStyle["size1"]}` },
                //{ title: 'eventKindType', key: 'eventKindType', both: true },// nur zum Testen
                {
                    title: 'Time', className: `${eventStyle["colSize"]} ${eventStyle["size3"]}`, key: 'timestamp', both: true, child: ({ created, command, timestamp }) =>
                    {
                        return <>
                            {formatDate(command ? new Date(created) : timestampToDate(timestamp), { timeZone: 'UTC', dateStyle: "medium", timeStyle: "medium" })}
                        </>;
                    }
                },
                {
                    title: 'Element', className: `${eventStyle["colSize"]} ${eventStyle["size2"]}`, key: 'zone', both: true, child: ({ command, firstElementOfRange, firstZoneOfRange, lastElementOfRange, lastZoneOfRange, element, zone }) =>
                    {
                        if (command) {
                            return <>
                                {formatLogicalAddress(firstElementOfRange, firstZoneOfRange) || '...'} - {formatLogicalAddress(lastElementOfRange, lastZoneOfRange) || '...'}
                            </>;
                        }
                        return <>{formatLogicalAddress(element, zone) || '...'}</>;

                    }
                },
                {
                    title: 'Event', className: `${eventStyle["colSize"]} ${eventStyle["size4"]}`, key: 'eventKindType', both: true, child: ({ command, userUserName, eventKindType }) =>
                    {

                        return <>
                            {command &&
                                <>
                                    {userUserName ? `[${userUserName}]` : t("[USER_OPERATION]")}<span> :: </span>
                                </>
                            }
                            {t(eventDetails(eventKindType)?.translationKey || "eventKindType is not implementet jet")}
                        </>;
                    }
                },
                //{ title: 'Detailed text', key: 'detailedText', breakpoint: 1200, up: true, localeCompare(b['detailedText']) },
                { title: 'Text1', key: 'elementText', both: true },
                { title: 'Text2', key: 'groupText', both: true },
                { title: 'Member', className: `${eventStyle["colSize"]} ${eventStyle["size1"]}`, key: 'panelNumber', both: true },
                {
                    title: 'Details', className: `${eventStyle["colSize"]} ${eventStyle["size2"]}`, key: 'details', both: true, child: ({ backplaneNumber, busIdentifier, softwareDeviceType }) =>
                    {
                        const slotNumber = slotIdentifier(backplaneNumber, busIdentifier);
                        return <>{slotNumber} {t(deviceType(softwareDeviceType))}</>;
                    }
                },
            ]}
        />
    </div>;
};

export default EventMemory;