import { useModifySystemShareDisplay, useSystemShareDisplay } from '../../../../../../../ReactData/system';
import SettingsCol from '../../../../../../../Settings/SettingsCol/SettingsCol';
import { Props } from './types';
import
{
    SystemShareDisplay
} from '@ews/react-data';

import NumberRange from '../components/NumberRange';

const View: React.FC<Props> = ({ systemId, userId }) =>
{

    const display = useSystemShareDisplay(systemId || "", userId!);
    const modify = useModifySystemShareDisplay();
    const modifyData = async (data: SystemShareDisplay) => await modify(systemId!, userId!, data);

    return < SettingsCol
    //size='12' xl='12' md='12' lg='12' sm='12'
    >
        <NumberRange
            label='Zone'
            from={display.fromZone}
            to={display.toZone}
            onChange={(fromZone, toZone) => modifyData({ fromZone, toZone } as SystemShareDisplay)}
        />

        <NumberRange
            label='Action'
            from={display.fromActuation}
            to={display.toActuation}
            onChange={(fromActuation, toActuation) => modifyData({ fromActuation, toActuation } as SystemShareDisplay)}
        />

        <NumberRange
            label='AlarmingDevice'
            from={display.fromAlarmingDevice}
            to={display.toAlarmingDevice}
            onChange={(fromAlarmingDevice, toAlarmingDevice) => modifyData({ fromAlarmingDevice, toAlarmingDevice } as SystemShareDisplay)}
        />
        <NumberRange
            label='TransmissionDevice'
            from={display.fromTransmissionDevice}
            to={display.toTransmissionDevice}
            onChange={(fromTransmissionDevice, toTransmissionDevice) => modifyData({ fromTransmissionDevice, toTransmissionDevice } as SystemShareDisplay)}
        />

    </SettingsCol>;
};
export default View;
