import { Customer, CustomerDetails, PaginatedResult, PaginationRequest } from "@ews/react-data";
import { useL10n } from "@ews/react-localization-context";
import { IonCol, IonIcon, IonItem, useIonRouter } from "@ionic/react";
import { caretBack, caretForward } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import FilterList from "../FilterList/FilterList";
import List from "../List/List";
import { pageRange } from "../List/Pagination/pagerange";
import { useMyProfile } from "../Login/LoginProvider";
import Sidebar from "../Navigation/Sidebar/Customer";
import { useCustomer, useFetchCustomerList } from "../ReactData/customer";
import { useIsMobile } from "../Responsive";
import SettingsTextInput from "../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { Filter } from "../System/Reports/types/reportTypes";
import { CustomersListProps } from "./type";

import { Link } from "react-router-dom";
import { useNamedRoutes } from "../NamedRoutes";
import style from './CustomerList.module.scss';


type ListFilter = {
    name?: string;
    parentId?: string,
    customerNumber?: number | string;
};

const standardFilter: Filter<ListFilter> = {
    sortCriteria: "name",
    sortOrder: "ASC"
};

const CustomerList: React.FC<CustomersListProps> = ({
    parentId,
    isFilterOpen,
    onIsOpen
}) =>
{

    const { customerId } = useMyProfile();
    const router = useIonRouter();
    parentId = parentId || customerId;


    const filterDebounceTime = 900;
    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    const { generate } = useNamedRoutes();


    const [currentCustomers, setCurrentCustomers] = useState<Customer[]>([]);

    //const [filterDetailsOpen, setFilterDetailsOpen] = useState(true);

    const page = useRef<number | undefined>(0);
    const numberOfPages = useRef<number | undefined>(10);
    const numberOfItemsPerPage = useRef(pageRange[1]);

    const filter = useFetchCustomerList();

    const [currentState, setState] = useState<PaginationRequest & ListFilter>({});
    const parentCustomer = useCustomer(parentId || "") as Customer & CustomerDetails;

    // if (parentId) {
    //     currentState.parentId = parentId;
    // }

    async function handleFilterResponse(PaginationRequest: PaginationRequest & ListFilter)
    {
        PaginationRequest = { ...PaginationRequest, parentId: parentId || "" };
        const response: PaginatedResult<Customer> = await filter(PaginationRequest) as PaginatedResult<Customer>;

        const { currentPage, pageSize, results, totalCountOfPages } = response;

        page.current = currentPage;
        numberOfPages.current = totalCountOfPages;
        numberOfItemsPerPage.current = pageSize;

        setCurrentCustomers(results);
    }
    useEffect(() =>
    {
        handleFilterResponse(currentState);
    }, [currentState, parentId]);

    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <FilterList
            isDetailsHidden={false}
            onIsOpen={onIsOpen}
            isOpen={isFilterOpen}
            //onDetailOpen={setFilterDetailsOpen}
            onReset={() =>
            {
                setState({});
            }}

            details={<>
                <IonCol>
                    <SettingsTextInput
                        id="name"
                        text="Name"
                        debounce={filterDebounceTime}
                        value={currentState.name}

                        onChange={(name) =>
                        {
                            const currentFilter = { ...currentState };

                            if (name.length) currentFilter.name = name;
                            else delete currentFilter.name;

                            setState(currentFilter);
                        }}
                    />
                </IonCol>
                <IonCol>
                    <SettingsTextInput
                        id="customerNumber"
                        text="customerNumber"
                        debounce={filterDebounceTime}
                        value={currentState.customerNumber?.toString()}

                        onChange={(customerNumber) =>
                        {
                            const currentFilter = { ...currentState };

                            if (customerNumber.length) currentFilter.customerNumber = customerNumber;
                            else delete currentFilter.customerNumber;

                            setState(currentFilter);
                        }}
                    />
                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>} >
            <IonCol></IonCol>
            <IonCol></IonCol>
            <IonCol></IonCol>
        </FilterList >

        <IonItem>
            {parentId && <>
                <Link to={parentCustomer.parentId ? generate("customer:customers", { id: parentCustomer.parentId }) : generate("customers")}><IonIcon icon={caretBack} /> {t("Zurück")}</Link>&nbsp;|&nbsp;
                <span>{[parentCustomer.name, parentCustomer.name2, parentCustomer.name3].join(", ")}</span>
            </>}
        </IonItem>


        < List
            rows={currentCustomers}

            sort={currentState.sortCriteria ? {
                key: currentState.sortCriteria,
                direction: currentState.sortOrder || "ASC"
            } : undefined}

            onSort={(sortCriteria, sortOrder) => setState({ ...currentState, sortCriteria, sortOrder })}
            onPageChange={(page) => setState({ ...currentState, page })}

            currentPage={page.current}
            numberOfPages={numberOfPages.current}

            /* maxHeight={mobileView ? undefined : '50vh'} */
            link={{ url: "customer", paramsKey: "id" }}
            onMenu={(id: string, onMenuToggle: () => void) => <Sidebar onMenuToggle={onMenuToggle} routeParams={{ id }} />}
            headers={
                [

                    /*      */
                    {
                        title: '',
                        key: 'children',
                        className: style.childIndicator,
                        child: (row) =>
                        {
                            if (!row.children) return <></>;

                            const link = generate("customer:customers", { id: row.id });
                            return <div onClick={(e) =>
                            {
                                e.preventDefault(); router.push(link);
                            }}> <IonIcon icon={caretForward} /></div >;
                        },
                        desktop: true,
                    },
                    {
                        title: 'id',
                        key: 'customerNumber',
                        both: true,
                    },
                    {
                        title: 'Name',
                        key: 'name',
                        both: true,
                    },
                    {
                        title: 'Name 2',
                        key: 'name2',
                        breakpoint: 1500, up: true,
                    },
                    {
                        title: 'Name 3',
                        key: 'name3',
                        breakpoint: 1900, up: true,
                    },
                    {
                        title: 'Street',
                        key: 'street',
                        both: true
                    },
                    {
                        title: 'Street 2',
                        key: 'street2',
                        breakpoint: 1900, up: true,
                    },
                    {
                        title: 'zip',
                        key: 'zip',
                        breakpoint: 1200, up: true,
                    },
                    {
                        title: 'city',
                        key: 'city',
                        breakpoint: 1200, up: true,
                    },
                    {
                        title: 'country',
                        key: 'country',
                        breakpoint: 1500, up: true,
                    },

                    /*    {
                           title: 'Land',
                           key: 'land',
                           breakpoint: 1500, up: true,
                             }, */
                    /*     {
                            title: 'created',
                            key: 'created',
                            both: true,
                              }, */
                ]}

        />

    </div >;
};

export default CustomerList;