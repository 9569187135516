import { SystemTextMessages } from '@ews/react-data';
import { useL10n } from '@ews/react-localization-context';
import { IonButton, IonHeader, IonItem, IonList, IonModal, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useCreateSystemTextMessages, useModifySystemTextMessages } from '../../../../ReactData/system';
import SettingsCheckbox from '../../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox';
import SettingsSelect from '../../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect';
import SettingsSelectOption from '../../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption';
import SettingsTextInput from '../../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput';
import { Data } from '../types';
import style from "./Modal.module.scss";
import { ModalProps } from './types';

const Modal: React.FC<ModalProps> = ({ systemId, isOpen, type, data, onDismiss, onUpdate, title }) =>
{

    const languages = ["de", "en", "es", "ie"];
    const { translate: t, getLocale } = useL10n();

    const standard = {
        telephoneNumber: "",
        alarm: false,
        preAlarm: false,
        language: getLocale() || "en",
    };

    const modal = useRef<HTMLIonModalElement>(null);

    const [_data, setData] = useState<Data>(standard);


    const disableSaveButton = !((_data.telephoneNumber?.length || 0) > 0 && (_data.language?.length || 0) > 0);


    const [error, setError] = useState("");

    const updateTextMesssage = useModifySystemTextMessages();

    const createTextMessage = useCreateSystemTextMessages();

    async function edit()
    {
        if (systemId && data?.id) {
            await updateTextMesssage(systemId, _data as SystemTextMessages, data.id);
        };
        if (onUpdate) onUpdate();
        close();
    }
    async function create()
    {
        if (systemId) {
            await createTextMessage(systemId, _data as SystemTextMessages);
        };
        if (onUpdate) onUpdate();
        close();
    }

    function onWillDismiss()
    {
        onDismiss();
    }

    function close()
    {
        setData(standard);
        onDismiss();
        modal.current?.dismiss();
    }

    useEffect(() =>
    {
        if (type === "edit" && data) {
            setData(data);
        } else {
            setData(standard);
        }

    }, [data, type]);

    return <>
        <IonModal
            ref={modal}
            className={style.modal}
            isOpen={isOpen}
            onWillDismiss={onWillDismiss}
        >
            <IonHeader>
                <IonToolbar>
                    <div
                        className={style.header}
                    >
                        <IonTitle>{`${t(title)}`}</IonTitle>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonList
                className="ion-padding"
                color="primary">
                <SettingsTextInput
                    text={"Phone number"}
                    inputType='tel'
                    value={_data?.telephoneNumber || ""}
                    debounce={500}
                    onChange={(telephoneNumber) =>
                    {
                        setError(!telephoneNumber || !telephoneNumber.length ? t("please type in a phone number") : '');
                        setData({ ..._data, telephoneNumber });
                    }}
                />

                <SettingsSelect text={'Language'}
                    value={_data?.language}
                    onSelect={(language) =>
                    {
                        setData({ ..._data, language });
                    }}
                >
                    {languages.map((language, index) => <SettingsSelectOption
                        key={index}
                        text={language}
                        value={language}
                    />)}
                </SettingsSelect>

                <div className={style.checkboxContainer}>
                    <div className={style.checkboxItem}>

                        <SettingsCheckbox
                            checked={_data.alarm}
                            text={"Alarm"}
                            onChange={(alarm) =>
                            {
                                setData({ ..._data, alarm });
                            }} />
                    </div>
                    <div className={style.checkboxItem}>

                        <SettingsCheckbox
                            checked={_data.preAlarm}
                            text={"Pre Alarm"}
                            onChange={(preAlarm) =>
                            {
                                setData({ ..._data, preAlarm });
                            }} />
                    </div>
                </div>

                <IonItem lines={error.length ? "full" : "none"}>
                    <div className={style.error}>
                        <IonText color={"danger"}>{error}</IonText>
                    </div>
                </IonItem>
            </IonList>
            <div className={`${style.buttonContainer} ${style.marginBottom}`}>
                <IonButton
                    slot="start"
                    color={"danger"}
                    onClick={() => close()}>
                    {t("Cancel")}
                </IonButton>
                <IonButton
                    slot="end"
                    color={"success"}
                    disabled={type === "new" ? disableSaveButton : false}
                    onClick={() =>
                    {
                        if (type === "edit") {
                            edit();
                        } else {
                            create();
                        }
                    }}>
                    {t("Save")}
                </IonButton>
            </div>
        </IonModal>
    </>;

};


export default Modal;
