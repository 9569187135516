import { IonButton, IonCol, IonLabel, IonRow } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import FilterList from "../../../FilterList/FilterList";

import { useL10n } from "@ews/react-localization-context";

import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";

import { SystemMeasurements, PaginationRequest, SystemOperate } from "@ews/react-data";
import { useFetchSystemMeasurement, useOperateSystem } from "../../../ReactData/system";
import { useIsMobile } from "../../../Responsive";
import DatePicker from "../../../Settings/SettingsComponents/SettingsRangeInput/components/DatePicker/DatePicker";
import { Filter } from "../types/reportTypes";
import analogueStyle from "./AnalogueValues.module.scss";
import { AnalogueFilterKeys, AnalogueValuesProps } from "./types";
import { loopType } from "@ews/zlt-events";

const AnalogueValues: React.FC<AnalogueValuesProps> = ({
    system,
    isFilterOpen,
    onIsOpen
}) =>
{
    const filterData = useFetchSystemMeasurement();
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();
    const operate = useOperateSystem();

    const page = useRef<number | undefined>();
    const numberOfPages = useRef<number | undefined>();
    const numberOfItemsPerPage = useRef(pageRange[1]);
    const [list, setList] = useState<SystemMeasurements[]>([]);

    const standardFilter: Filter<AnalogueFilterKeys> = {
        sortCriteria: "created",
        sortOrder: "DESC"
    };

    const [currentState, setState] = useState<Filter<AnalogueFilterKeys>>(standardFilter);

    async function getFilteredData(id: string, filter: PaginationRequest & AnalogueFilterKeys)
    {
        const response = await filterData(id, filter);
        if (!response) return;

        const { results, currentPage, pageSize, totalCountOfPages } = response;

        page.current = currentPage;
        numberOfPages.current = totalCountOfPages;
        numberOfItemsPerPage.current = pageSize;

        setList(results);
    }


    useEffect(() =>
    {
        const { id } = system;
        if (!id) return;

        getFilteredData(id, currentState);

    }, [currentState, system]);


    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <FilterList
            onReset={() => setState(standardFilter)}
            isDetailsHidden={false}
            onIsOpen={onIsOpen}
            isOpen={isFilterOpen}
            details={<>
                <IonCol size="3">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonLabel style={{ maxWidth: "fit-content" }}>{t("Date")}</IonLabel>
                    </div>
                    <DatePicker
                        className={analogueStyle.dateTime}
                        childText={{ start: "start", end: "end" }}
                        value={{}}
                        divider={false}
                        position="row"
                        onChange={(range) =>
                        {
                            const startDate = range.from;
                            const endDate = range.to;
                            const currentFilter = { ...currentState };

                            currentFilter.created = `${startDate},${endDate}`;
                            setState({ ...currentFilter });
                        }}
                    />
                </IonCol>

                <IonCol>
                    <IonRow class="ion-justify-content-end">
                        <IonButton
                            color={"success"}
                            onClick={async () =>
                            {
                                const { id } = system;
                                if (!id) return;
                                await operate(id, { command: 'measure' } as SystemOperate);
                            }}
                        >
                            <span> {`${t("Load")} ${t("Measures")}`}</span>
                        </IonButton>
                    </IonRow>
                </IonCol>
            </>
            }
        >
        </FilterList >
        <List
            rows={list}
            sort={
                currentState.sortCriteria ? {
                    key: currentState.sortCriteria,
                    direction: currentState.sortOrder || "ASC"
                } : undefined
            }
            onSort={(sortCriteria, sortOrder) => setState({ ...currentState, sortCriteria, sortOrder })}

            currentPage={page.current}
            numberOfPages={numberOfPages.current}

            onPageChange={(page) => setState({ ...currentState, page })}

            //maxHeight={mobileView ? "86vh" : "47vh"}
            headers={[
                { title: 'Time.', key: 'created', both: true },
                { title: 'No.', key: 'zone', both: true },
                { title: 'Element.', key: 'element', breakpoint: 1200, up: true },
                { title: 'Current value', key: 'analogueValue', breakpoint: 1200, up: true, child: ({ analogueValue }) => analogueValue < 0xff ? analogueValue : "..." },
                { title: 'Dirt%', key: 'value', breakpoint: 1200, up: true, child: ({ value }) => value < 0xff ? value : "..." },
                { title: 'Alarming value', key: 'alarmThreshold', breakpoint: 1200, up: true, child: ({ alarmThreshold }) => alarmThreshold < 0xff ? alarmThreshold : "..." },
                { title: '+12MNTH', key: 'prediction12m', breakpoint: 1200, up: true, child: ({ prediction12m }) => prediction12m < 0xff ? prediction12m : "..." },
                { title: 'Maint', key: 'monthsLeft', breakpoint: 1200, up: true, child: ({ monthsLeft }) => monthsLeft < 0xff ? monthsLeft : "..." },
                { title: 'Element type', key: 'elementType', breakpoint: 1200, up: true, child: ({ elementType }) => t(loopType(elementType)) },
                { title: 'Element text', key: 'text', breakpoint: 1200, up: true },
            ]}
        />
    </div>;
};

export default AnalogueValues;

