import { IonCol, IonList } from "@ionic/react";
import React from "react";
import { SettingsColProps } from "./types";
import style from './SettingsCol.module.scss';
import { useIsMobile } from "../../Responsive";

const SettingsCol: React.FC<SettingsColProps> = ({ children, size = '12', sm = '12', md = '12', lg = '6', xl = '4' }) =>
{
    const mobileView = useIsMobile();

    const border = mobileView ? 'none' : 'right';

    return <IonCol
        className={children !== undefined ? style[border] : ''}
        size={size}
        sizeSm={sm}
        sizeMd={md}
        sizeLg={lg}
        sizeXl={xl}
    >
        <IonList lines="full" className={`${style.list} ${mobileView ? style.fullWidth : ""}`}>
            {children}
        </IonList>
    </IonCol>;
};

export default SettingsCol;