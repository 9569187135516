import
{
    CapacitorWifiConnect
} from '@falconeta/capacitor-wifi-connect';

import
{
    useEffect,
    useState
} from 'react';

export const getWLanSSID = async () =>
{
    return await CapacitorWifiConnect.getDeviceSSID();
};

export const useWLanSSID = (refresh: number = 0) =>
{
    const [SSID, setSSID] = useState<string | null>(null);

    useEffect(() =>
    {
        let timeout: NodeJS.Timeout;

        const updateSSID = () =>
        {
            getWLanSSID().then(({ value }) =>
            {
                setSSID((current) => (value !== current) ? value : current);
            }).catch((e) =>
            {
                console.log(e);
                setSSID(null);
            });

            if (refresh) {
                timeout = setTimeout(updateSSID, refresh);
            }
        };

        updateSSID();

        return () =>
        {
            clearTimeout(timeout);
        };

    }, [refresh]);

    return SSID;
};