import { useL10n } from "@ews/react-localization-context";
import { SystemType } from "@ews/react-data";
import { IonButton, IonHeader, IonList, IonModal, IonTitle, IonToolbar, useIonRouter } from "@ionic/react";
import React, { useRef, useState } from "react";
import { SystemTypeIcon } from "../../Icons";
import { useNamedRoutes } from "../../NamedRoutes";
import
{
    useCreateSystem
} from "../../ReactData/system";
import { useIsMobile } from "../../Responsive";
import SettingsTextInput from "../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import modalStyle from "./NewSystemModal.module.scss";
import { NewSystemModalProps } from "./types";
import { IonModalCustomEvent, OverlayEventDetail } from "@ionic/core/components";
import CustomerTypeAhead from "../../Customer/CustomerTypeAhead";

const NewSystemModal: React.FC<NewSystemModalProps> = ({
    customerId,
    trigger,
    onDismiss
}) =>
{

    const mobileView = useIsMobile();
    const { translate: t } = useL10n();
    const modal = useRef<HTMLIonModalElement>(null);
    const [name, setName] = useState<string | null>(null);
    const [type, setType] = useState<keyof typeof SystemType>(SystemType.BC600);
    const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>(customerId);

    const { generate } = useNamedRoutes();
    const router = useIonRouter();
    const createSystem = useCreateSystem();

    async function confirm(name: string | null, type: keyof typeof SystemType)
    {
        if (!name || !type) return;

        try {
            const system = await createSystem(name, SystemType[type], selectedCustomerId);

            const id = system.id;
            modal.current?.dismiss();
            const link = generate('system:settings', { id });
            router.push(link);
        } catch (err) {
            console.log(err);
        }
    }

    function onWillDismiss(event: IonModalCustomEvent<OverlayEventDetail<any>>)
    {
        setType(SystemType.BC216);
        setName(null);
        onDismiss && onDismiss(event);
    }

    return <IonModal
        ref={modal}
        className={modalStyle.newSystemModal}
        trigger={trigger}
        breakpoints={mobileView ? [.6] : undefined}
        onWillDismiss={onWillDismiss}>
        <IonHeader>
            <IonToolbar>
                <div className={`${modalStyle.titleCenter}`}>
                    <IonTitle>{`${t("new System")}`}</IonTitle>
                </div>
            </IonToolbar>
        </IonHeader>
        <IonList
            className="ion-padding"
            color="primary">
            <SettingsTextInput
                text={"Name"}
                value={name || undefined}
                onChange={(value) =>
                {
                    setName(value);
                }} />

            <CustomerTypeAhead
                label="Customer"
                currentId={customerId || ""}
                onChange={(customerId) => setSelectedCustomerId(customerId)}
            />

            {/*     <SettingsSelect
                value={SystemType[type]}
                text={"[SID_KIND]"}
                onBlur={({ value }) =>
                {

                    const type: keyof typeof SystemType = value;
                    setType(type);
                }}>
                {Object.keys(SystemType).map(((type, index) =>
                {
                    return <SettingsSelectOption key={index} className={modalStyle.option} value={`${type}`} text={`${type}`} />;
                }))}
            </SettingsSelect> */}
            <div className={`${modalStyle.buttonContainer}`}>
                {Object.keys(SystemType).map(((system, index) =>
                {
                    const Type = SystemType[system as SystemType];

                    return <div
                        className={modalStyle.padding}
                        key={index}

                    >
                        <div
                            className={`${modalStyle.pointer} ${modalStyle.typeButton} ${type === system ? modalStyle.currentType : ""}`}
                            onClick={() => setType(Type)}
                        >
                            <SystemTypeIcon type={Type} />
                        </div>
                        <div className={modalStyle.center}>
                            {`${Type} `}
                        </div>
                    </div>;
                }))}
            </div>
        </IonList>
        <div className={`${modalStyle.buttonContainer} ${modalStyle.marginBottom}`}>
            <IonButton
                slot="start"
                color={"danger"}
                onClick={() => modal.current?.dismiss()}>{t("Cancel")}
            </IonButton>
            <IonButton
                disabled={!name}
                slot="end"
                color={"success"}
                onClick={() => confirm(name, type)}>
                {t("Create")}
            </IonButton>
        </div>
    </IonModal>;

};


export default NewSystemModal;