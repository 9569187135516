import
{
    Geolocation,
    Position
} from '@capacitor/geolocation';

import
{
    useEffect,
    useState
} from 'react';

import
{
    Polygon
} from "geojson";

import inside from 'point-in-polygon';

export const locationInArea = (location: Position, area: Polygon) =>
{
    console.log(location);
    const point = [location.coords.longitude, location.coords.latitude];
    const polygon = area.coordinates.slice().pop() as number[][];

    return inside(point, polygon);

};

export const getGeoLocation = async () =>
{
    return await Geolocation.getCurrentPosition();
};

export const useGeoLocation = (refresh: number = 0) =>
{
    const [position, setPosition] = useState<Position | null>(null);

    useEffect(() =>
    {
        let timeout: NodeJS.Timeout;

        const updatePosition = () =>
        {
            getGeoLocation().then((current) =>
            {
                setPosition((position) =>
                {
                    return (current.coords.latitude !== position?.coords.latitude ||
                        current.coords.longitude !== position?.coords.longitude) ? current : position;
                });

                if (refresh) {
                    timeout = setTimeout(updatePosition, refresh);
                }
            }).catch((e) =>
            {
                setPosition(null);

                if (refresh) {
                    timeout = setTimeout(updatePosition, refresh);
                }
            });
        };

        updatePosition();

        return () =>
        {
            clearTimeout(timeout);
        };

    }, [refresh]);

    return position;
};