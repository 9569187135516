import { useL10n } from "@ews/react-localization-context";
import
{
    useSystemDetails
} from "../../../ReactData/system";

import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsTitle from "../../../Settings/SettingsTitle/SettingsTitle";
import { GPSSnapshotProps } from "./types";
import { IonCol, IonItem, IonRow } from "@ionic/react";

const GPSSnapshot: React.FC<GPSSnapshotProps> = ({ systemId }) =>
{
    const details = useSystemDetails(systemId);
    const { translate: t } = useL10n();

    return <>
        <SettingsCol xl="12" >
            <SettingsTitle
                text={"GPS Details"}
                endText={':'} />
            {details.GPSSnapshotURL ? <IonRow>
                <IonCol class="ion-text-center">
                    <img src={details.GPSSnapshotURL} alt={t('GPS Details')} />
                </IonCol>
            </IonRow> :
                <>
                    {t('No GPS Details available yet')}
                </>}
        </SettingsCol>

    </>;
};

export default GPSSnapshot;