import
{
    useL10n
} from "@ews/react-localization-context";

import
{
    IonItem,
    IonModal,
    IonPopover,
    IonTitle
} from "@ionic/react";

import
{
    Responsive,
    useIsMobile
} from "../../Responsive";

import
{
    SystemStatus
} from "@ews/react-data";

import React, {
    ReactNode,
    useRef,
    useState
} from "react";

import
{
    firstLetterUpperCase
} from "../GroupOverview/helper";

import GruppList from "../GruppList/GruppList";
import styleGroup from './GroupItem.module.scss';

import Icon, { IconName } from "@ews/react-icons";

import
{
    AuthorizationLevel,
    NoDeviceNumber,
    eventGetSymbol,
    formatLogicalAddress
} from "@ews/zlt-events";

import { PositionSide } from "../../List/types";

import Operate, {
    getAddress,
    SelectRange, validOperations
} from "../../Navigation/Sidebar/Operate";

import
{
    ReactClickEvent
} from "../../types";

import
{
    Col
} from "../GruppList/components/Col";

import
{
    MenuComponent
} from "../GruppList/components/Menu";

import
{
    useAuthorization
} from "../../Authorization";

import
{
    useCurrentSystemStatus
} from "../../System/SystemStatus";

import
{
    SystemElement,
    SystemGroup
} from "../../ReactData/system";

import listStyle from "../GruppList/GruppList.module.scss";

type EventItemProps = {
    groups: SystemGroup[];
    title: string;
    className?: string;
};

function maxScreenHeight(height: number, y: number, side: PositionSide, margin: number = 10): number
{
    return (side === "bottom" ? (height - y) : y) - margin;
}

function pxToPercent(childSize: number, parentSize: number): number
{
    return Math.round((childSize / parentSize) * 100);
}

const GroupItem: React.FC<EventItemProps> = ({ groups, title, className }) =>
{
    const mobileView = useIsMobile();
    const { authorizationLevel } = useAuthorization();
    const systemStatus = useCurrentSystemStatus();

    const { translate: t } = useL10n();

    const [range, setRange] = useState<SelectRange>({});

    const ContextMenuElement = mobileView ? IonModal : IonPopover;

    const [ContextMenu, setContextMenu] = useState<{ contextMenu?: ReactNode, event?: ReactClickEvent; }>({});
    const contextDirection = useRef<PositionSide>("bottom");
    const contextMaxHeight = useRef<number | null>(1050);

    const rangeSelect = (target: SystemGroup | SystemElement) =>
    {

        let hideMenu = false;

        if (!range.begin) {
            hideMenu = true;
            range.begin = target;
        } else {
            range.end = target;
        }

        setRange(range);
        if (hideMenu) dismissOptions();


        return range;

    };

    const presentOptions = (target: SystemGroup | SystemElement, e?: ReactClickEvent) =>
    {

        if (range.end) {
            delete (range.end);
            delete (range.begin);
            setRange({});
        }

        const contextMenu = <Operate
            target={target}
            range={range}
            onRangeSelect={rangeSelect}
            onMenuToggle={dismissOptions}
        />;


        const { clientY, view } = e!.nativeEvent as PointerEvent;
        const screenHeight = view?.innerHeight || 0;
        const heightPercent = pxToPercent(clientY, screenHeight);
        const side = heightPercent >= 70 ? "top" : "bottom";

        if (e?.cancelable) e?.preventDefault();
        setContextMenu({ contextMenu, event: e });

        contextDirection.current = side as PositionSide;
        contextMaxHeight.current = maxScreenHeight(screenHeight, clientY, side as PositionSide);
    };

    const dismissOptions = () =>
    {
        setContextMenu({});
    };

    const getEventIcon = (target: SystemGroup | SystemElement): IconName =>
    {
        const address = getAddress(target);
        const { eventKindType } = target;

        try {
            return eventGetSymbol(eventKindType!, address);
        } catch (e) {

            return "none";
        };
    };

    return <section className={`${className} ${listStyle.rowContainer}`}>
        <IonItem
            lines="full"
            color={mobileView ? undefined : 'medium'}
        >
            <IonItem
                lines="none"
                color={mobileView ? undefined : 'medium'}
                className={`${styleGroup.center}`}
            >
                <IonTitle
                >
                    {t(firstLetterUpperCase(title))}
                </IonTitle>

            </IonItem>
        </IonItem>

        <ContextMenuElement
            reference="event"
            side={contextDirection.current}
            event={ContextMenu.event?.nativeEvent}
            initialBreakpoint={mobileView ? .6 : undefined}
            // className={mobileView ? listStyle.menu : ''}
            isOpen={Boolean(ContextMenu.contextMenu)}
            onDidDismiss={dismissOptions}
            keepContentsMounted={true}
            style={{ alignItems: "start", "--max-height": `${contextMaxHeight.current}px `, }}
        >
            {ContextMenu.contextMenu}
        </ContextMenuElement>

        < GruppList
            rows={groups}
            //maxHeight={mobileView ? "83vh" : "49vh"} // mobile mit header "83.5vh"
            onMenu={(group, element, e) =>
            {
                const hasOperations = validOperations(authorizationLevel, element || group).length;
                if (hasOperations && systemStatus === SystemStatus.ONLINE) presentOptions(element || group, e);
            }}
            headers={
                [
                    {
                        title: () => <div className={`${listStyle.settingsIcon}`}></div>,
                        render: (group, element) =>
                        {
                            const hasOperations = group.operate && validOperations(authorizationLevel, element || group).length;

                            return <Responsive desktop>
                                {hasOperations && systemStatus === SystemStatus.ONLINE ?
                                    <MenuComponent onClick={(e) => presentOptions(element || group, e)} />
                                    : <div className={`${listStyle.settingsIcon}`}></div>}
                            </Responsive>;
                        }
                    },
                    {
                        title: () => <Col className={`${listStyle.status}`}>{t('[SID_MNU_TAB_STATUS]')}</Col>,
                        render: (group, element) => (
                            <div className={`${listStyle.status}`}>
                                <Icon icon={getEventIcon(element || group)} />
                            </div>
                        )
                    },
                    // {
                    //     title: () => <></>,
                    //     render: (group, element) => (
                    //         <RangeBox data-testId="rangebox"
                    //             onChange={(checked) => console.log()}
                    //             checked={false} />
                    //     )
                    // },
                    {
                        title: () => (
                            <Col className={`${listStyle.number}`}>{t('TD_NUMMER_FWI_INP')}</Col>
                        ),
                        render: (group, element) => (
                            <Col className={`${listStyle.number}`}>{formatLogicalAddress(group.id!, element?.id || NoDeviceNumber, 1)}</Col>
                        )
                    },
                    {
                        title: () => <Col>{t('[SID_DLG_TXT_TEXT1]')}</Col>,
                        render: (group, element) => (
                            <Col>
                                <Responsive mobile>{(element || group).text}</Responsive>
                                <Responsive desktop>{group.text}</Responsive>
                            </Col>
                        )
                    },
                    {
                        title: () => (
                            <Col>{t('TD_ELEMENTTEXT')}</Col>
                        ),
                        render: (group, element) => (
                            <Responsive desktop>
                                <Col>{element?.text}</Col>
                            </Responsive>
                        )
                    },
                    {
                        title: () => <div className={`${listStyle.settingsIcon}`}></div>,
                        render: (group, element) => (

                            <Responsive mobile>
                                {authorizationLevel > AuthorizationLevel.LEVEL1 && systemStatus === SystemStatus.ONLINE ?
                                    <MenuComponent onClick={(e) => presentOptions(element || group, e)} />
                                    : <div className={`${listStyle.settingsIcon}`}></div>}
                            </Responsive>
                        )
                    },

                ]}
        />
    </section>;

};

export default GroupItem;
