import { useL10n } from "@ews/react-localization-context";
import { System, SystemDetails, SystemStatus } from "@ews/react-data";
import { IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow } from "@ionic/react";
import React from "react";
import { useIsMobile } from "../Responsive";
import { TooltipIcon } from "../ToolTip/useTooltip";
import style from './SystemDetails.module.scss';

type DetailsProps = {
    system: System,
    details: SystemDetails;
};

const Details: React.FC<DetailsProps> = ({ system, details }) =>
{
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();

    return (
        <IonGrid>
            <IonRow>
                <IonCol size="6" class={style["overview-main-container"]} >
                    <IonList data-testid="list" lines={mobileView ? "full" : 'inset'} className={mobileView ? style['ion-item-mobile'] : ''}>

                        <IonItem lines="full" className={`${style.overflow}`}>

                            <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonLabel >{`${t('[SID_MNU_TAB_INFO_SCR]')}:`}<TooltipIcon text={t("der name der Anlage")} /></IonLabel>
                            </IonItem>

                            <IonItem lines='none' className={`${style.overflow}`}>
                                <IonLabel>{system?.name || ''}</IonLabel>
                            </IonItem>
                        </IonItem>

                        <IonItem lines="full" className={`${style.overflow}`}>
                            <span slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonItem lines='none' >
                                    <IonLabel >{`${t('Alias')}:`}<TooltipIcon text={t("der Alias name der Anlage")} /></IonLabel>
                                </IonItem>
                            </span>
                            <IonItem lines='none' className={`${style.overflow}`}>
                                <IonLabel>{system?.alias || ''}</IonLabel>
                            </IonItem>
                        </IonItem>



                        <IonItem lines="full" className={`${style.overflow}`}>
                            <span slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonItem lines='none' >
                                    <IonLabel >{`${t('Systemnumber')}:`}<TooltipIcon text={""} /></IonLabel>
                                </IonItem>
                            </span>
                            <IonItem lines='none' className={`${style.overflow}`}>
                                <IonLabel>{system.systemNumber || ''}</IonLabel>
                            </IonItem>
                        </IonItem>

                        <IonItem lines="full" className={`${style.overflow}`}>
                            <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonLabel>{`${t('SystemType')}:`}</IonLabel>
                            </IonItem>
                            <IonItem lines="none" className={`${style.overflow}`}>
                                <IonLabel>{system?.systemType}</IonLabel>
                            </IonItem>
                        </IonItem>

                        <IonItem lines="full" className={`${style.overflow}`}>
                            <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonLabel>{`${t('[SID_SYSTEM_KEY]')}:`}</IonLabel>
                            </IonItem>
                            <IonItem lines="none" className={`${style.overflow}`}>
                                <IonLabel>{system?.systemKey || ''}</IonLabel>
                            </IonItem>
                        </IonItem>

                        <IonItem lines="full" className={`${style.overflow}`}>
                            <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonLabel>{`${t('Location')}:`}</IonLabel>
                            </IonItem>
                            <IonItem lines="none" className={`${style.overflow}`}>
                                <IonLabel>{details.location || ''}</IonLabel>
                            </IonItem>
                        </IonItem>

                        <IonItem lines="full" className={`${style.overflow}`}>
                            <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonLabel>{`${t('Site')}:`}</IonLabel>
                            </IonItem>
                            <IonItem lines="none" className={`${style.overflow}`}>
                                <IonLabel>{details.site || ''}</IonLabel>
                            </IonItem>
                        </IonItem>

                        <IonItem lines="full" className={`${style.overflow}`}>
                            <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonLabel>{`${t('Street')}:`}</IonLabel>
                            </IonItem>
                            <IonItem lines="none" className={`${style.overflow}`}>
                                <IonLabel>{details.street || ''}</IonLabel>
                            </IonItem>
                        </IonItem>

                        <IonItem lines="full" className={`${style.overflow}`}>
                            <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonLabel>{`${t('ZIP')}:`}</IonLabel>
                            </IonItem>
                            <IonItem lines="none" className={`${style.overflow}`}>
                                <IonLabel>{details.zip || ''}</IonLabel>
                            </IonItem>
                        </IonItem>

                        <IonItem lines="full" className={`${style.overflow}`}>
                            <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonLabel>{`${t('City')}:`}</IonLabel>
                            </IonItem>
                            <IonItem lines="none" className={`${style.overflow}`}>
                                <IonLabel>{details.city || ''}</IonLabel>
                            </IonItem>
                        </IonItem>

                        <IonItem lines="full" className={`${style.overflow}`}>
                            <IonItem lines='none' slot="start" className={`${style.firstColum} ${style.overflow}`}>
                                <IonLabel>{`${t('Country')}:`}</IonLabel>
                            </IonItem>
                            <IonItem lines="none" className={`${style.overflow}`}>
                                <IonLabel>{details.country || ''}</IonLabel>
                            </IonItem>
                        </IonItem>

                    </IonList>
                </IonCol>
                <IonCol hidden={!details.exposeGPS} size="6" class="ion-text-center">
                    <img alt="GPS Information" src={details.GPSSnapshotURL}></img>
                </IonCol>
            </IonRow>
        </IonGrid >
    );
};

export default Details;