
import
{
    PaginatedResult,
    PaginationRequest,
    System,
    SystemDetails,
    SystemEvents,
    SystemLicense,
    SystemMaintenanceReport,
    SystemMeasurements,
    SystemNotifications,
    SystemOperate,
    SystemPermissions,
    SystemPlan,
    SystemRTM,
    SystemShare,
    SystemShareDisplay,
    SystemShareNotifications,
    SystemShareOperate,
    SystemTextMessages,
    SystemType,
    SystemElement as _SystemElement,
    SystemGroup as _SystemGroup,
    SystemSetup as _SystemSetup,
    zoneAccessPermitted
} from "@ews/react-data";

import
{
    ReactTopics
} from '@ews/websocket-service';

import
{
    useLoadingIndicator
} from "../Loading";

import
{
    useTopicData,
    useWebsocket
} from './ReactDataProvider';


import
{
    useCallback,
    useEffect,
    useState
} from 'react';

import
{
    EventKindType,
    NoDeviceNumber,
    eventDisplay,
    eventPriorityCompare,
    isGroup
} from "@ews/zlt-events";

import
{
    getResult,
    groupDisplay,
    isSuccess
} from '@ews/zlt-operate';

import
{
    useAuthorization
} from "../Authorization";

import
{
    useIonToast
} from '@ionic/react';

import
{
    useMyProfileId
} from "../Login/LoginProvider";


export { type PaginatedResult };

export type SystemElement = _SystemElement & {
    eventKindType?: EventKindType;
    groupText?: string,
    rtm?: SystemRTM[],
};

export type SystemGroup = _SystemGroup & {
    elements?: SystemElement[],
    hidden?: boolean,
    operate?: boolean,
    eventKindType?: EventKindType;
    rtm?: SystemRTM[],
};

export type SystemSetup = _SystemSetup & {
    groups: SystemGroup[];
};


export const useSystem = (systemId: string) =>
{
    return useTopicData<System>(`${ReactTopics.system.withParams({ systemId })}`, <System>{});
};

export const useSystemDetails = (systemId: string) =>
{
    return useTopicData<SystemDetails>(`${ReactTopics.systemDetails.withParams({ systemId })}`, <SystemDetails>{});
};

export const useSystemEvents = (systemId: string) =>
{
    return useTopicData<SystemEvents[]>(`${ReactTopics.systemEvents.withParams({ systemId })}`, <SystemEvents[]>[]);
};

export const useSystemPermissions = (systemId: string) =>
{
    return useTopicData<SystemPermissions>(`${ReactTopics.systemPermissions.withParams({ systemId })}`, <SystemPermissions>{});
};

export const useSystemShare = (systemId: string, userId: string) =>
{
    return useTopicData<SystemShare>(`${ReactTopics.systemShare.withParams({ systemId, userId })}`, <SystemShare>{});
};

export const useSystemShareDisplay = (systemId: string, userId: string) =>
{
    return useTopicData<SystemShareDisplay>(`${ReactTopics.systemShareDisplay.withParams({ systemId, userId })}`, <SystemShareDisplay>{});
};
export const useSystemShareOperate = (systemId: string, userId: string) =>
{
    return useTopicData<SystemShareOperate>(`${ReactTopics.systemShareOperate.withParams({ systemId, userId })}`, <SystemShareOperate>{});
};
export const useSystemShareNotifications = (systemId: string, userId: string) =>
{
    return useTopicData<SystemShareNotifications>(`${ReactTopics.systemShareNotifications.withParams({ systemId, userId })}`, <SystemShareNotifications>{});
};

export const useSystemLicense = (systemId: string) =>
{
    return useTopicData<SystemLicense>(`${ReactTopics.systemLicense.withParams({ systemId })}`, <SystemLicense>{});
};

export const useSystemNotifications = (systemId: string) =>
{
    return useTopicData<SystemNotifications>(`${ReactTopics.systemNotifications.withParams({ systemId })}`, <SystemNotifications>{});
};

export const useSystemMaintenance = (systemId: string) =>
{
    return useTopicData<SystemMaintenanceReport>(`${ReactTopics.systemMaintenance.withParams({ systemId })}`, <SystemMaintenanceReport>{});
};

export function useSystemRTM(systemId: string, group?: false): SystemRTM[];
export function useSystemRTM(systemId: string, group: true): Record<string, SystemRTM[]>;
export function useSystemRTM(systemId: string, group: Boolean = false)
{
    const { authorizationLevel } = useAuthorization();
    const me = useMyProfileId();

    const systemShareDisplay = useSystemShareDisplay(systemId, me || "");

    const rtm = useTopicData<SystemRTM[]>(`${ReactTopics.systemRTM.withParams({ systemId })}`, []).filter((rtm) =>
    {
        const { properties } = rtm;
        return eventDisplay(properties || 0, authorizationLevel) && zoneAccessPermitted(rtm.zone, rtm.numberGroup, systemShareDisplay);
    });

    if (!group) return rtm;

    const table: Record<string, SystemRTM[]> = {};

    for (const item of rtm) {

        const { numberGroup, zone, element } = item;

        table[`${numberGroup}x${zone}`] = table[`${numberGroup}x${zone}`] || [];

        if (isGroup({ zone: zone!, element: element! })) {

            table[`${numberGroup}x${zone}`] = table[`${numberGroup}x${zone}`] || [];
            table[`${numberGroup}x${zone}`].push(item);
        } else {

            table[`${numberGroup}x${zone}x${element}`] = table[`${numberGroup}x${zone}x${element}`] || [];
            table[`${numberGroup}x${zone}x${element}`].push(item);
        }

    }

    return table;
};

// const displayByShare = (group: SystemGroup, share: any) =>
// {

//     const id = group.id;

//     switch (group.numberGroup) {
//         case NumberGroup.Zone:
//             return share.fromZone <= id && share.toZone >= id;
//         case NumberGroup.ActuationDevice:
//             return share.fromActuation <= id && share.toActuation >= id;
//         case NumberGroup.AlarmingDevice:
//             return share.fromAlarmingDevice <= id && share.toAlarmingDevice >= id;
//         case NumberGroup.TransmissionDevice:
//             return share.fromTransmissionDevice <= id && share.toTransmissionDevice >= id;
//     }

//     return false;

// };

export const useSystemSetup = (systemId: string) =>
{
    const { authorizationLevel } = useAuthorization();
    const rtm = useSystemRTM(systemId, true);
    const me = useMyProfileId();

    /* const systemShareDisplay = useSystemShareDisplay(systemId, me || ""); */
    const systemShareOperate = useSystemShareOperate(systemId, me || "");

    const setup: SystemSetup = useTopicData<SystemSetup>(`${ReactTopics.systemSetup.withParams({ systemId })}`, <SystemSetup>{ groups: <SystemGroup[]>[] });

    for (const group of setup.groups) {

        const zone = group.id!;
        const numberGroup = group.numberGroup!;

        const groupProperties = group.properties1!;

        const address = {
            zone: group.id!,
            element: NoDeviceNumber
        };

        group.hidden = !groupDisplay(group.numberGroup!, group.properties1 || 0, authorizationLevel, address) || !zoneAccessPermitted(zone, numberGroup, systemShareOperate);
        group.operate = zoneAccessPermitted(zone, numberGroup, systemShareOperate);

        const groupEvents = rtm[`${numberGroup}x${zone}`] || [];
        const elementEvents = [];


        //

        for (const item of group.elements!) {

            const element = item.id!;
            const events = rtm[`${numberGroup}x${zone}x${element}`] || [];
            elementEvents.push(...events);

            item.rtm = events.length ? events : groupEvents;

            item.properties1 = groupProperties;
            item.eventKindType = item.rtm.map((l: SystemRTM) => l.eventKindType!).sort(eventPriorityCompare).pop();

        }

        group.rtm = groupEvents.length ? groupEvents : elementEvents;
        group.eventKindType = group.rtm.map((l: SystemRTM) => l.eventKindType!).sort(eventPriorityCompare).pop();

    }

    return setup;
};

export const useSystemPlan = (systemId: string) =>
{
    return useTopicData<SystemPlan[]>(`${ReactTopics.systemPlan.withParams({ systemId })}`, []);
};

export const useMySystems = (filterCriteria: PaginationRequest) =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    const [systemsResult, setSystemsResult] = useState<PaginatedResult<System>>({
        currentPage: 0,
        pageSize: 0,
        results: [],
        totalCountOfEntries: 0,
        totalCountOfPages: 0
    });

    const handleSystem = useCallback((systemReceived: System) =>
    {

        setSystemsResult((result: PaginatedResult<System>) =>
        {

            result.results = result.results.map((system: System) =>
            {
                return system.id === systemReceived.id ? systemReceived : system;
            });

            return { ...result };
        });
    }, []);

    const handleResult = useCallback((result: PaginatedResult<System>) =>
    {
        setSystemsResult((previousResult: PaginatedResult<System>) =>
        {
            previousResult.results.forEach((system: System) =>
            {
                const topic = ReactTopics.system.withParams({ systemId: system.id! });
                provider.unsubscribe(`${topic}`, handleSystem);
            });

            result.results.forEach((system: System) =>
            {
                const topic = ReactTopics.system.withParams({ systemId: system.id! });
                provider.subscribe(`${topic}`, handleSystem);
            });

            return result;

        });
    }, [handleSystem, provider]);

    const doRequest = useCallback(async (filterCriteria: PaginationRequest) =>
    {
        handleResult(await provider.fetch<PaginatedResult<System>>(filterCriteria, `${ReactTopics.mySystems}`, loadingToggle));
    }, [handleResult, loadingToggle, provider]);

    useEffect(() =>
    {
        doRequest(filterCriteria);
    }, [filterCriteria, doRequest]);

    return systemsResult;
};


export const useModifySystem = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, data: System) => provider.modify(data, `${ReactTopics.system.withParams({ systemId })}`, loadingToggle);
};

export const useModifySystemDetails = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, data: SystemDetails) => provider.modify(data, `${ReactTopics.systemDetails.withParams({ systemId })}`, loadingToggle);
};

export const useModifySystemNotifications = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, data: SystemNotifications) => provider.modify(data, `${ReactTopics.systemNotifications.withParams({ systemId })}`, loadingToggle);
};

export const useModifySystemLicense = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, data: SystemLicense) => provider.modify(data, `${ReactTopics.systemLicense.withParams({ systemId })}`, loadingToggle);
};

export const useModifySystemPermissions = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, data: SystemPermissions) => provider.modify(data, `${ReactTopics.systemPermissions.withParams({ systemId })}`, loadingToggle);
};

export const useModifySystemSystemShare = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, userId: string, data: SystemPermissions) => provider.modify(data, `${ReactTopics.systemShare.withParams({ systemId, userId })}`, loadingToggle);
};

export const useModifySystemShareDisplay = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, userId: string, data: SystemPermissions) => provider.modify(data, `${ReactTopics.systemShareDisplay.withParams({ systemId, userId })}`, loadingToggle);
};

export const useModifySystemShareNotifications = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, userId: string, data: SystemPermissions) => provider.modify(data, `${ReactTopics.systemShareNotifications.withParams({ systemId, userId })}`, loadingToggle);
};

export const useModifySystemShareOperate = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, userId: string, data: SystemPermissions) => provider.modify(data, `${ReactTopics.systemShareOperate.withParams({ systemId, userId })}`, loadingToggle);
};

export const useModifySystemMaintenance = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, data: SystemMaintenanceReport) => provider.modify(data, `${ReactTopics.systemMaintenance.withParams({ systemId })}`, loadingToggle);
};

export const useModifySystemElement = () => 
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, data: SystemElement) => provider.modify(data, `${ReactTopics.systemElement.withParams({ systemId })}`, loadingToggle);
};

export const useModifySystemPlan = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();
    return async (systemId: string, data: SystemPlan, planId: string,) => provider.modify(data, `${ReactTopics.systemPlan.withParams({ systemId, planId })}`, loadingToggle);
};

export const useModifySystemTextMessages = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();
    return async (systemId: string, data: SystemTextMessages, id: string) => provider.modify(data, `${ReactTopics.systemTextMessages.withParams({ systemId, id })}`, loadingToggle);
};

export const useDeleteSystemPlan = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();
    return async (systemId: string, planId: string,) => provider.delete(`${ReactTopics.systemPlan.withParams({ systemId, planId })}`, loadingToggle);
};

export const useDeleteSystemTextMessages = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();
    return async (systemId: string, id: string,) => provider.delete(`${ReactTopics.systemTextMessages.withParams({ systemId, id })}`, loadingToggle);
};

export const useCreateSystemSetupCSV = () => 
{
    const loadingToggle = useLoadingIndicator();
    const [presentToast] = useIonToast();
    const provider = useWebsocket();

    return async (systemId: string, zones: File, actuations: File): Promise<SystemSetup> => new Promise((resolve, reject) =>
    {

        const actuationsReader = new FileReader();
        const zonesReader = new FileReader();

        actuationsReader.onload = async () =>
        {
            const importActuationsCSV = (actuationsReader.result as string).substring(37);
            const importZonesCSV = (zonesReader.result as string).substring(37);

            try {
                const result = (await provider.create({ importActuationsCSV, importZonesCSV }, `${ReactTopics.systemSetup.withParams({ systemId })}`) as SystemSetup);
                resolve(result);
            } catch (e: any) {
                presentToast(e as string, 3000);
                reject(e);
            }
            loadingToggle(false);
        };

        zonesReader.onload = async () =>
        {
            actuationsReader.readAsDataURL(actuations);
        };

        loadingToggle(true);
        zonesReader.readAsDataURL(zones);

    });
};

export const useCreateSystemSetup = () => 
{
    const loadingToggle = useLoadingIndicator();
    const [presentToast] = useIonToast();
    const provider = useWebsocket();

    return async (systemId: string, data: File): Promise<SystemSetup> => new Promise((resolve, reject) =>
    {
        const reader = new FileReader();

        reader.onload = async () =>
        {
            const base64 = (reader.result as string).substring(37);

            try {
                const result = (await provider.create({ importXMLC: base64 }, `${ReactTopics.systemSetup.withParams({ systemId })}`) as SystemSetup);
                resolve(result);
            } catch (e: any) {
                presentToast(e as string, 3000);
                reject(e);
            }
            loadingToggle(false);
        };

        loadingToggle(true);
        reader.readAsDataURL(data);

    });
};

export const useCreateSystem = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (name: string, systemType: SystemType, customerId?: string) =>
    {
        const system: System = <System>{ name, systemType, customerId };
        return await provider.create(system, `${ReactTopics.systemCreate}`, loadingToggle);
    };
};

export const useCreateSystemShare = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, userId: string) =>
    {
        const systemShare: any = { systemId, userId };
        return await provider.create({} as SystemShare, `${ReactTopics.systemShare.withParams(systemShare)}`, loadingToggle);
    };
};

export const useCreateSystemTextMessages = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, data: SystemTextMessages) =>
    {
        return await provider.create(data, `${ReactTopics.systemTextMessages.withParams({ systemId })}`, loadingToggle);
    };
};


export type SystemOperationConfirmation = {
    code: number,
    message: string;
};

export const useOperateSystem = () =>
{
    const loadingToggle = useLoadingIndicator();
    const [presentToast] = useIonToast();
    const provider = useWebsocket();

    return async (systemId: string, data: SystemOperate) =>
    {
        try {

            const systemOperate = await provider.operate<SystemOperate, SystemOperate>(data, `${ReactTopics.systemOperate.withParams({ systemId })}`, loadingToggle);

            if (!isSuccess(systemOperate.result!)) {
                throw getResult(systemOperate.result!);
            }

            return systemOperate;

        } catch (e: any) {
            presentToast(e as string, 3000);
        }
    };
};


export const useFetchSystemEvents = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch<PaginatedResult<SystemEvents>>(filterCriteria, `${ReactTopics.systemEvents.withParams({ systemId })}`, loadingToggle);
    };
};

export const useFetchSystemList = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch<PaginatedResult<System>>(filterCriteria, `${ReactTopics.mySystems}`, loadingToggle);
    };
};
export const useTypeAheadSystemList = () =>
{
    const provider = useWebsocket();

    return async (filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch<PaginatedResult<System>>(filterCriteria, `${ReactTopics.mySystems}`);
    };
};

export const useTypeAheadFetchSystemSystemTextMessages = () =>
{
    const provider = useWebsocket();

    return async (systemId: string, filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch<PaginatedResult<SystemTextMessages>>(filterCriteria, `${ReactTopics.systemTextMessages.withParams({ systemId })}`);
    };
};

export const useFetchSystemMeasurement = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch<PaginatedResult<SystemMeasurements>>(filterCriteria, `${ReactTopics.systemMeasurements.withParams({ systemId })}`, loadingToggle);
    };
};

export const useFetchSystemShares = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch<PaginatedResult<SystemShare>>(filterCriteria, `${ReactTopics.systemShares.withParams({ systemId })}`, loadingToggle);
    };
};

export const useFetchSystemTextMessages = () =>
{
    const loadingToggle = useLoadingIndicator();
    const provider = useWebsocket();

    return async (systemId: string, filterCriteria: PaginationRequest,) =>
    {
        return provider.fetch<PaginatedResult<SystemTextMessages>>(filterCriteria, `${ReactTopics.systemTextMessages.withParams({ systemId })}`, loadingToggle);
    };
};