import { useL10n } from "@ews/react-localization-context";
import { NotificationChannel, SystemNotifications } from "@ews/react-data";
import { IonLabel } from "@ionic/react";
import { useRef, useState } from "react";

import
{
    useModifySystemNotifications,
    useSystem,
    useSystemNotifications
} from "../../../ReactData/system";

import { useIsMobile } from "../../../Responsive";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsTableRow from "../../../Settings/SettingsComponents/SettingsTable/SettingsRow/SettingsTableRow";
import SettingsTableColumn from "../../../Settings/SettingsComponents/SettingsTable/SettingsTableColumn/SettingsTableColumn";
import SettingsTableGrid from "../../../Settings/SettingsComponents/SettingsTable/SettingsTableGrid/SettingsTableGrid";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { NotificationsSettingsProps } from "./types";
import notificationStyle from "./NotificationsSettings.module.scss";

const NotificationsSettings: React.FC<NotificationsSettingsProps> = ({ systemId }) =>
{
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();

    const modifySystemNotifications = useModifySystemNotifications();
    const system = useSystem(systemId);
    const notification = useSystemNotifications(systemId);

    const [sendEmailFireAlarmPDF, setSendEmailFireAlarmPDF] = useState<boolean>(true);
    const receiver1Ref = useRef<HTMLIonInputElement>(null);
    const receiver2Ref = useRef<HTMLIonInputElement>(null);
    const receiver3Ref = useRef<HTMLIonInputElement>(null);

    function saveSystemNotifications(key: keyof SystemNotifications, value: any)
    {
        if (!system.id) return;
        modifySystemNotifications(system.id!, { [key]: value } as SystemNotifications);
    }

    function checkedDevice(key: keyof SystemNotifications, device: keyof typeof NotificationChannel, checked: boolean)
    {

        if (!(key in notification)) return;

        const { EMAIL, PUSH } = NotificationChannel;

        if (NotificationChannel[device] === EMAIL) {
            (notification[key] as NotificationChannel) = checked ? ((notification[key] as NotificationChannel) | EMAIL) : ((notification[key] as NotificationChannel) & PUSH);
        }
        if (NotificationChannel[device] === PUSH) {
            (notification[key] as NotificationChannel) = checked ? ((notification[key] as NotificationChannel) | PUSH) : ((notification[key] as NotificationChannel) & EMAIL);
        }
        saveSystemNotifications(key, notification[key]);
    }

    return <>
        <SettingsCol >


            <SettingsTableGrid>
                <SettingsTableRow hidden={mobileView}>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('event')}:`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <p style={{ textAlign: "center", margin: 0 }}>{`${t('Push')}:`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <p style={{ textAlign: "center", margin: 0 }}>{`${t('E-mail')}:`}</p>
                    </SettingsTableColumn>
                </SettingsTableRow>


                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_HK_FIRE_ALARMS]')}`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"Push"}
                            item={mobileView}
                            //container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.fireAlarm ? (notification.fireAlarm & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                            onChange={(checked) => { checkedDevice("fireAlarm", "PUSH", checked); }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"E-mail"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.fireAlarm ? (notification.fireAlarm & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                            onChange={(checked) => { checkedDevice("fireAlarm", "EMAIL", checked); }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('[SID_DEV_STATE_PREALARM]')}`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"Push"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.preAlarm ? (notification.preAlarm & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                            onChange={(checked) => { checkedDevice("preAlarm", "PUSH", checked); }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"E-mail"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.preAlarm ? (notification.preAlarm & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                            onChange={(checked) => { checkedDevice("preAlarm", "EMAIL", checked); }} />
                    </SettingsTableColumn>
                </SettingsTableRow>


                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('[SID_EV_FIRE_PREALARM_SH]')}`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"Push"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.fireAlert ? (notification.fireAlert & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                            onChange={(checked) => { checkedDevice("fireAlert", "PUSH", checked); }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"E-mail"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.fireAlert ? (notification.fireAlert & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                            onChange={(checked) => { checkedDevice("fireAlert", "EMAIL", checked); }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_ACT_OUTP]')}`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"Push"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.activeOutput ? (notification.activeOutput & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                            onChange={(checked) => { checkedDevice("activeOutput", "PUSH", checked); }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"E-mail"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.activeOutput ? (notification.activeOutput & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                            onChange={(checked) => { checkedDevice("activeOutput", "EMAIL", checked); }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_FAULTS]')}`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"Push"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer} label={mobileView}
                            checked={notification.fault ? (notification.fault & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                            onChange={(checked) => { checkedDevice("fault", "PUSH", checked); }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"E-mail"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.fault ? (notification.fault & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                            onChange={(checked) => { checkedDevice("fault", "EMAIL", checked); }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('[SID_EV_TECHN_MESS_ALARM]')}`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"Push"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.technicalMessage ? (notification.technicalMessage & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                            onChange={(checked) => { checkedDevice("technicalMessage", "PUSH", checked); }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"E-mail"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.technicalMessage ? (notification.technicalMessage & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                            onChange={(checked) => { checkedDevice("technicalMessage", "EMAIL", checked); }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('[SID_EV_TECHN_MESS_SW_OFF_ZONE_SH]')}`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"Push"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.disablement ? (notification.disablement & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                            onChange={(checked) => { checkedDevice("disablement", "PUSH", checked); }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"E-mail"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.disablement ? (notification.disablement & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                            onChange={(checked) => { checkedDevice("disablement", "EMAIL", checked); }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_EXT_EV]')}`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"Push"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.extinguishingEvent ? (notification.extinguishingEvent & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                            onChange={(checked) => { checkedDevice("extinguishingEvent", "PUSH", checked); }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"E-mail"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.extinguishingEvent ? (notification.extinguishingEvent & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                            onChange={(checked) => { checkedDevice("extinguishingEvent", "EMAIL", checked); }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <SettingsTableColumn size="4" center={false}>
                        <IonLabel style={{ margin: 0 }}>{`${t('[SID_MNU_TAB_STATUS]')}`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"Push"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.status ? (notification.status & NotificationChannel.PUSH) === NotificationChannel.PUSH : false}
                            onChange={(checked) => { checkedDevice("status", "PUSH", checked); }} />
                    </SettingsTableColumn>
                    <SettingsTableColumn >
                        <SettingsCheckbox
                            text={"E-mail"}
                            item={mobileView}
                            container={mobileView}
                            className={notificationStyle.checkboxContainer}
                            label={mobileView}
                            checked={notification.status ? (notification.status & NotificationChannel.EMAIL) === NotificationChannel.EMAIL : false}
                            onChange={(checked) => { checkedDevice("status", "EMAIL", checked); }} />
                    </SettingsTableColumn>
                </SettingsTableRow>

            </SettingsTableGrid>

        </SettingsCol>

        <SettingsCol >
            <SettingsCheckbox
                disabled
                text={"PDF E-Mail mit Brandalarm"}
                checked={sendEmailFireAlarmPDF}
                onChange={(checked) => { setSendEmailFireAlarmPDF(checked); }} >
                <SettingsTextInput
                    disabled
                    text={"Empfänger 1"}
                    inputRef={receiver1Ref}
                    inputType={"text"}
                    value={"receiver1"}
                /*onBlur={({value}) => { setNotificationssData({ ...notificationssData, receiver1: value }); }} */
                />
                <SettingsTextInput
                    disabled
                    text={"Empfänger 2"}
                    inputType={"text"}
                    inputRef={receiver2Ref}
                    value={"receiver2"}
                /*onBlur={({value}) => { setNotificationssData({ ...notificationssData, receiver2: value }); }} */
                />
                <SettingsTextInput
                    disabled
                    text={"Empfänger 3"}
                    inputType={"text"}
                    inputRef={receiver3Ref}
                    value={"receiver3"}
                /*onBlur={({value}) => { setNotificationssData({ ...notificationssData, receiver3: value }); }} */
                />
            </SettingsCheckbox>
        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default NotificationsSettings;