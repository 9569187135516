import { formatLogicalAddress } from "@ews/zlt-events";
import { IonCard, IonItem, IonTitle } from "@ionic/react";
import React, { useRef, useState } from "react";
import { SystemElement } from "../../../ReactData/system";
import { useIsMobile } from "../../../Responsive";
import eventAnimation from "../../elements/Melder/Animation.module.scss";
import Melder from "../../elements/Melder/Melder";
import ContextMenu from "./ContextMenu/ContextMenu";
import dropStyle from './DropZone.module.scss';
import DropZoneCardHeader from "./Header/Header";
import ImageContainer from "./ImageContainer/ImageContainer";
import { DevicesNotAtSamePositions, PXtoPercent, getPositionPXtoPercent, isValidPositionToSetDevice } from "./helper";
import { DropZoneProps } from "./types";


const DropZone: React.FC<DropZoneProps> = ({ imagePath, currentElement, onData, elementList, title }) =>
{
    const containerSize = 100;
    const elementSizeDevider = 4;

    const elmentWidth = 74; // durchschnittlich ist auf dem Plan der Melder Element 74px breit
    const elmentHeight = 40; // durchschnittlich ist auf dem Plan der Melder Element 40px hoch

    const [currentMenu, setCurrentMenu] = useState(-1);
    let [rotate, setRotate] = useState(0);
    const mobileView = useIsMobile();
    const [zoom, setZoom] = useState(0);

    const yRef = useRef(0);
    const xRef = useRef(0);


    const [info, setInfo] = useState('');

    const containerRef = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const isInRange = useRef<boolean>(true);
    const imgSizeProzent = 100;//80;
    const validPosition = (
        option: {
            list: SystemElement[],
            x: number,
            y: number,
            containerWidth: number,
            containerHeight: number,
            noMargin?: boolean,
            devider?: number;
        }) =>
    {

        const notAtSamePosition = DevicesNotAtSamePositions(
            option.list,
            option.x,
            option.y
        );

        const range = isValidPositionToSetDevice(
            option.list,
            option.x!,
            option.y!,
            option.containerWidth!,
            option.containerHeight!,
            option.noMargin ? option.noMargin : false,
            option.devider
        );
        return notAtSamePosition && range;
    };

    const rotateImg = () =>
    {
        containerRef.current!.style.transform = `rotate(${rotate}deg)`;
    };

    const overHandler = (e: React.PointerEvent<HTMLImageElement>) =>
    {

        if (!currentElement.id) return;
        const { offsetX, offsetY } = e.nativeEvent;

        yRef.current = offsetY;
        xRef.current = offsetX;

        const xPercent = getPositionPXtoPercent(offsetX, imgRef.current?.clientWidth!);
        const yPercent = getPositionPXtoPercent(offsetY, imgRef.current?.clientHeight!);

        const DeviceWidthPercent = PXtoPercent((elmentWidth), imgRef.current?.clientWidth!);
        const DeviceHeightPercent = PXtoPercent((elmentHeight), imgRef.current?.clientHeight!);

        const inRange = validPosition({
            list: elementList,
            x: xPercent,
            y: yPercent,
            containerWidth: DeviceWidthPercent,
            containerHeight: DeviceHeightPercent,
            noMargin: false,
            devider: elementSizeDevider
        });
        isInRange.current = inRange;
        if (!inRange) setInfo('you can`t set a detector here');
        else setInfo('');
        //}

    };



    /*  const leaveHandler = (e: React.DragEvent<HTMLImageElement>) =>
     {
         const { offsetX, offsetY } = e.nativeEvent;
 
         const xPercent = getPositionPXtoPercent(offsetX, imgRef.current?.clientWidth!);
         const yPercent = getPositionPXtoPercent(offsetY, imgRef.current?.clientHeight!);
 
         const imgWidthPercent = PXtoPercent(ElementRef.current?.clientWidth!, imgRef.current?.clientWidth!);
         //- PXtoPercent(5, imgRef.current?.clientWidth!);
         const imgHeightPercent = PXtoPercent(ElementRef.current?.clientHeight!, imgRef.current?.clientHeight!);
 
         const width = ((xRef.current) / imgRef.current?.clientWidth!) * 100; // px to %
         const height = ((yRef.current) / imgRef.current?.clientHeight!) * 100; // px to %
         e.preventDefault();
         const canSetDevice = validPosition({
             list: elementList,
             x: xPercent,
             y: yPercent,
             containerWidth: imgWidthPercent,
             containerHeight: imgHeightPercent
         });
         if (canSetDevice) {
 
          
             onData({ planX: xPercent, planY: yPercent, planIcon: imagePath! });//rotate: 0
 
             MelderRef.current[`${currentDevice.groupId}/${currentDevice.id}`] = {
                 planX: xRef.current,
                 planY: yRef.current,
                 planIcon: imagePath || "",
             };
         }
     }; */

    const clickHandler = (e: React.MouseEvent) =>
    {
        const { offsetX, offsetY } = e.nativeEvent;


        const xPercent = getPositionPXtoPercent(offsetX, imgRef.current?.clientWidth!);
        const yPercent = getPositionPXtoPercent(offsetY, imgRef.current?.clientHeight!);


        const halfDeviceWidthPercent = PXtoPercent((elmentWidth / 2), imgRef.current?.clientWidth!);
        const halfDeviceHeightPercent = PXtoPercent((elmentHeight / 2), imgRef.current?.clientHeight!);

        const inRange = validPosition({
            list: elementList,
            x: xPercent,
            y: yPercent,
            containerWidth: halfDeviceWidthPercent,
            containerHeight: halfDeviceHeightPercent,
            noMargin: false,
            devider: elementSizeDevider
        });
        if (inRange && currentElement.groupId) {
            onData({ systemPlanX: xPercent - halfDeviceWidthPercent, systemPlanY: yPercent - halfDeviceHeightPercent, systemPlanIcon: imagePath! } as SystemElement);
        }
    };


    return <>
        <IonCard className={dropStyle.card}>
            <DropZoneCardHeader
                info={info}
                zoom={zoom}
                onZoom={function (zoom: number): void
                {
                    setZoom(zoom);
                }} />

            <ImageContainer
                containerRef={containerRef}
                imgRef={imgRef}
                zoom={zoom}
                containerSize={containerSize}
                //onDragLeave={leaveHandler}
                onClick={clickHandler}
                onHover={overHandler}
            //onDragOver={(coords) =>overHandler(coords)}
            >

                {elementList.map((element, index) =>
                {

                    const sizeUnit = "%";
                    const { systemPlanX, systemPlanY, systemPlanIcon, ...elem } = element;

                    const text = formatLogicalAddress(elem.groupId!, elem.id!, 1);
                    return <span
                        key={`device-${text}-${index}`}
                        style={{
                            position: 'absolute',
                            fontSize: mobileView ? ".5rem" : "",
                            background: "rgba(255,255,255,0.6)",
                            top: `${systemPlanY}${sizeUnit}`,
                            left: `${systemPlanX}${sizeUnit}`,
                        }}
                        onContextMenu={(e) =>
                        {
                            e.preventDefault();
                            if (currentMenu === index) {
                                setCurrentMenu(-1);
                            };
                            setCurrentMenu(index);
                        }}>
                        <Melder
                            className={element.eventKindType ? eventAnimation.blink : ""}
                            draggable={false}
                            element={element}
                            icon={systemPlanIcon!}
                            height={`${imgSizeProzent - zoom}%`}
                            width={`${imgSizeProzent - zoom}%`}
                            text={`${text}`}
                        />
                        <ContextMenu
                            menuColor={"primary"}
                            currentMenu={currentMenu}
                            id={index}
                            element={element}
                            /*    onChange={(id, position) =>
                               {
                                   const imgWidthPercent = PXtoPercent(ElementRef.current?.clientWidth!, imgRef.current?.clientWidth!) - PXtoPercent(5, imgRef.current?.clientWidth!);
                                   const imgHeightPercent = PXtoPercent(ElementRef.current?.clientHeight!, imgRef.current?.clientHeight!);
   
                                   const { planX, planY } = position;
                                   const inRange = validPosition({
                                       list: elementList,
                                       x: planX!,
                                       y: planY!,
                                       containerWidth: imgWidthPercent,
                                       containerHeight: imgHeightPercent,
                                       noMargin: false,
                                       devider: 2
                                   });
   
                                   if (inRange) setPositionsCorrection({ ...positionsCorrection, [id]: position });
   
                               }} */
                            onData={(coords) =>
                            {
                                onData(coords);
                            }}
                            onMenu={setCurrentMenu}
                        />
                    </span>;
                })}

            </ImageContainer>
            {title ? <IonItem color={'secondary'}>
                <div
                    className={dropStyle.center}
                >
                    <IonTitle >{title}</IonTitle>
                </div>
            </IonItem> : null}

        </IonCard>

    </>;
};

export default DropZone;