import { useL10n } from "@ews/react-localization-context";
import { DateTimeFormat, getDateFormat, User, UserDetails } from "@ews/react-data";
import { IonItem, IonText } from "@ionic/react";
import { useMyDetails } from "../../../Login/LoginProvider";
import { useModifyUser, useModifyUserDetails, useUser, useUserDetails } from "../../../ReactData/user";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import { LanguageFormatSettingsProps } from "./types";

const supportedTimezones = [
    "Europe/Vienna",
    "UTC"
];

const LanguageFormatSettings: React.FC<LanguageFormatSettingsProps> = ({ id }) =>
{
    const { translate: t } = useL10n();

    const user = useUser(id);
    const details = useUserDetails(id);
    const myDetails = useMyDetails();

    const modifyUserDetails = useModifyUserDetails();
    const modifyUser = useModifyUser();

    const modifyDetails = async (data: UserDetails) =>
    {
        await modifyUserDetails(user.id!, data);
    };

    const modify = async (data: User) =>
    {
        await modifyUser(user.id!, data);
    };


    return <>
        <SettingsCol>

            <SettingsSelect text={'[SID_MNU_VLI_LANGUAGE]'} value={user.locale} onSelect={(locale) => modify({ locale } as User)}>
                <SettingsSelectOption text={"Deutsch"} value={"de"} />
                <SettingsSelectOption text={"English"} value={"en"} />
            </SettingsSelect>

            <SettingsSelect text={"Time zone"} value={details.timezone} onSelect={(timezone) => modifyDetails({ timezone } as UserDetails)}>
                {supportedTimezones.map((timezone) =>
                {
                    return <SettingsSelectOption key={timezone} text={timezone} value={timezone} />;
                })}
            </SettingsSelect>

            <SettingsSelect
                text={"Date/time format"}
                value={details.dateTimeFormat || ""}
                onSelect={(dateTimeFormat) => modifyDetails({ dateTimeFormat } as UserDetails)}
            >
                {Object.keys(DateTimeFormat).map((time, index) =>
                {
                    const key = DateTimeFormat[time as keyof typeof DateTimeFormat];
                    return <SettingsSelectOption key={index} text={getDateFormat(key, new Date(), myDetails.timezone)} value={key} />;
                })}

            </SettingsSelect>

            <IonItem lines="none">
                <IonText>{t('Remarks')}</IonText>
            </IonItem>
            <IonItem>
                <textarea cols={30} rows={10}></textarea>
            </IonItem>

        </SettingsCol>

        <SettingsCol>
            {/* <SettingsSelect text={"[SID_MNU_HK_FIRE_ALARMS]"} endText={' in Tab'} >
                <SettingsSelectOption text={"Event1"} value={"1"} />
                <SettingsSelectOption text={"Event2"} value={"2"} />
            </SettingsSelect>

            <SettingsSelect text={"[SID_MNU_TAB_FAULTS]"} endText={' in Tab'} >
                <SettingsSelectOption text={"Event1"} value={"1"} />
                <SettingsSelectOption text={"Event2"} value={"2"} />
            </SettingsSelect>

            <SettingsSelect text={"[SID_MNU_TAB_DISABLEMENTS]"} endText={' in Tab'} value={LanguageFormatDataRef.current.disablement} onSelect={(value) => LanguageFormatDataRef.current.disablement = value}>
                <SettingsSelectOption text={"Event1"} value={"1"} />
                <SettingsSelectOption text={"Event2"} value={"2"} />
            </SettingsSelect>

            <SettingsSelect text={"TD_TECHNISCHE_MELDUNGEN"} value={LanguageFormatDataRef.current.technicalMessage} onSelect={(value) => LanguageFormatDataRef.current.technicalMessage = value}>
                <SettingsSelectOption text={"Event1"} value={"1"} />
                <SettingsSelectOption text={"Event2"} value={"2"} />
            </SettingsSelect>

            <SettingsSelect text={"Activations"} endText={' in Tab'} value={LanguageFormatDataRef.current.activations} onSelect={(value) => LanguageFormatDataRef.current.activations = value}>
                <SettingsSelectOption text={"Event1"} value={"1"} />
                <SettingsSelectOption text={"Event2"} value={"2"} />
            </SettingsSelect>

            <SettingsSelect text={"[SID_MNU_TAB_ACKN]"} endText={' in Tab'} value={LanguageFormatDataRef.current.confirmations} onSelect={(value) => LanguageFormatDataRef.current.confirmations = value}>
                <SettingsSelectOption text={"Event1"} value={"1"} />
                <SettingsSelectOption text={"Event2"} value={"2"} />
            </SettingsSelect>

            <SettingsSelect text={"[SID_MNU_TAB_TEST_COND]"} endText={' in Tab'} value={LanguageFormatDataRef.current.testConsditions} onSelect={(value) => LanguageFormatDataRef.current.testConsditions = value}>
                <SettingsSelectOption text={"Event1"} value={"1"} />
                <SettingsSelectOption text={"Event2"} value={"2"} />
            </SettingsSelect>

            <SettingsSelect text={"[SID_MNU_TAB_EXT_EV]"} endText={' in Tab'} value={LanguageFormatDataRef.current.extinguishingEvent} onSelect={(value) => LanguageFormatDataRef.current.extinguishingEvent = value}>
                <SettingsSelectOption text={"Event1"} value={"1"} />
                <SettingsSelectOption text={"Event2"} value={"2"} />
            </SettingsSelect>
 */}

        </SettingsCol>
        <SettingsCol>

        </SettingsCol>

    </>;
};

export default LanguageFormatSettings;