import
{
    IonCol,
    IonGrid,
    IonInput,
    IonRow
}
    from "@ionic/react";

import
{
    SwiperSlide,
    useSwiper
} from 'swiper/react';

import
{
    SystemRTM
} from "@ews/react-data";

import React, {
    useRef,
    useState
} from "react";

import
{
    useIsMobile
} from "../../Responsive";



import
{
    EventItem
} from "../EventItem/EventItem";

import
{
    distributeRTMFields
} from "../helper.ts/distributeRTMFields";

import
{
    EventObject,
    RTMFieldOrder
} from "../types";

import
{
    RTMCategory
} from "@ews/zlt-events";

import
{
    useL10n
} from "@ews/react-localization-context";

import EventCardButton from "../EventCardButton/EventCardButton";
import eventStyle from './EventOverview.module.scss';
import SettingsSwiper from "../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper";

const EventOverview: React.FC<{ rtm: SystemRTM[]; }> = ({ rtm }) =>
{
    const rtmFields = distributeRTMFields(rtm);
    const { translate: t } = useL10n();

    const slideToRef = useRef<number>(0);
    const SwiperRef = useRef<any>();

    const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
    {
        const swiper = useSwiper();

        return <IonInput
            label=""
            ref={swipeRef}
            onClick={(e) =>
            {
                swiper.slideToLoop(slideToRef.current);
            }}
            hidden />;
    };

    const swiperOption = {
        spaceBetween: 40,
        slidesPerView: 1,
        duration: '2s',
        animation: 'ease-in-out'
    };

    const mobileView = useIsMobile();

    const pageTabBarOptionList = [
        { label: 'Event 1', value: 'page 1' },
        { label: 'Event 2', value: 'page 2' }
    ];

    const [currentPage] = useState(pageTabBarOptionList[0].value);


    const SwiperComponent = mobileView ? SettingsSwiper : IonGrid;
    const SliderComponent = mobileView ? SwiperSlide : ({ children }: { children: React.ReactNode; }) => <>{children}</>;
    const ColComponent = mobileView ? ({ children }: { children: React.ReactNode; }) => <>{children}</> : IonCol;
    const RowComponent = mobileView ? ({ children }: { children: React.ReactNode; }) => <>{children}</> : IonRow;
    let secondPage: EventObject[] | [] = [];

    //if (eventList.length > 6 && !mobileView) secondPage = eventList.splice(6, eventList.length - 1)

    return <>{/* <IonContent className={eventStyle["event-card-content-container"]} style={mobileView ? { height: '85%' } : {}}>{} */}
        {/*  {secondPage.length && !mobileView ? <SettingsSegment value={currentPage} onSelect={(current) => setCurrentPage(current)}>
            <IonSegmentButton value="page 1">{'Event 1'}</IonSegmentButton>
            <IonSegmentButton value="page 2">{'Event 2'}</IonSegmentButton>
        </SettingsSegment> : null} */}

        <SwiperComponent
            className={eventStyle['event-swiper']}
            spaceBetween={swiperOption.spaceBetween}
            slidesPerView={1}
            loop
        >
            {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}
            {mobileView ? <SliderComponent key={'event-overview-slider-main'} >
                <IonGrid className={mobileView ? eventStyle['event-overview-grid'] : ''} >
                    <IonRow >
                        {RTMFieldOrder.map((categoryType, index) => 
                        {
                            const category: RTMCategory = RTMCategory[categoryType];
                            const rtm: SystemRTM[] = rtmFields[category]!;

                            return <IonCol size="4" key={categoryType} className={eventStyle["event-card-button"]} >
                                <EventCardButton

                                    count={rtm.length}
                                    swiperIndex={index + 1}
                                    title={t(`FC_${categoryType}`)}
                                    type={categoryType}
                                    onClick={(page) =>
                                    {
                                        slideToRef.current = page;
                                        SwiperRef.current.click();
                                    }} />
                            </IonCol>;
                        })}
                    </IonRow>
                </IonGrid>
            </SliderComponent> : null}

            <RowComponent className={eventStyle["event-row"]} hidden={secondPage.length && currentPage !== pageTabBarOptionList[0].value ? true : false}>
                <>
                    {RTMFieldOrder.map((categoryType) => 
                    {
                        const category: RTMCategory = RTMCategory[categoryType];
                        const rtm: SystemRTM[] = rtmFields[category]!;

                        return <SliderComponent key={categoryType} className={eventStyle['event-swiper']}>
                            <ColComponent size="4">
                                <EventItem rtm={rtm} title={t(`FC_${categoryType}`)} />

                            </ColComponent>
                        </SliderComponent>;
                    })}
                </>
            </RowComponent>
        </SwiperComponent >
        {
            secondPage.length ? <RowComponent hidden={currentPage !== pageTabBarOptionList[1].value ? true : false}>
                <></>
                {/*  {secondPage.map((event: EventObject, index: number) =>
            {

                return <SliderComponent key={'event-overview-slider-' + index} className={eventStyle['event-swiper']}>

                    <ColComponent size="4">
                        <EventItem event={event} />
                    </ColComponent>
                </SliderComponent>;

            })} */}
            </RowComponent> : null
        }

        {/* </IonContent> */}
    </>;
};
export default EventOverview;