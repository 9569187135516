import
{
    SystemRTM
} from "@ews/react-data";

import
{
    RTMCategory,
    eventDetails,
    eventRTMCategory
} from "@ews/zlt-events";


export const distributeRTMFields = (rtm: SystemRTM[]) =>
{
    const rtmFields: Record<RTMCategory, SystemRTM[]> = {
        [RTMCategory.Alarm]: [],
        [RTMCategory.TestCondition]: [],
        [RTMCategory.Fault]: [],
        [RTMCategory.TechnicalMessage]: [],
        [RTMCategory.Disablement]: [],
        [RTMCategory.ActiveOutput]: [],
        [RTMCategory.Confirmation]: [],
        [RTMCategory.ExtinguishingEvent]: [],
        [RTMCategory.None]: []
    };

    rtm.forEach((event: SystemRTM) =>
    {
        const { eventKindType } = event;

        const category = eventRTMCategory(eventKindType!);
        const details = eventDetails(eventKindType!);
        const rtm = { ...details, ...event };
        rtmFields[category]?.push(rtm as unknown as SystemRTM);

    });

    return rtmFields;
};