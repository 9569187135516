import { IonCol, IonContent, IonPage } from "@ionic/react";
import { useRef, useState } from "react";
import FilterList, { stringFilter } from "../../../FilterList/FilterList";
import { StingFilterType } from "../../../FilterList/types";
import List from "../../../List/List";
import { useNamedRoutes } from "../../../NamedRoutes";
import { useIsMobile } from "../../../Responsive";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import { getList } from "../../../TypeAhead/helper";
import TypeaheadString from "../../../TypeAhead/TypeAheadString";
import { MaintenanceReportProps } from "./types";
import { pageRange } from "../../../List/Pagination/pagerange";

const MaintenanceReport: React.FC<MaintenanceReportProps> = ({ system }) =>
{
    const { generate } = useNamedRoutes();

    const mobileView = useIsMobile();

    const [search, setSearch] = useState(0);

    const data = [
        {
            link: generate('systems'),
            nr: 'GRP 6',
            ele: '028',
            event: 'fire alarm',
            text1: 'Gruppe 6',
            text2: 'Detektor 6-028 Medicinrum and other special report things',
            checkAt: new Date().toDateString(),
        },
        {
            link: generate('systems'),
            nr: 'GRP 3',
            ele: '004',
            event: 'tech. messages',
            text1: 'Gruppe 3',
            text2: 'Detektor 3-012 WC ISEK',
            checkAt: new Date().toDateString(),
        }
    ];

    const [list, setList] = useState<any[] | null>(data);

    const [filterDetailsOpen, setFilterDetailsOpen] = useState(false);
    const [currentRowLimit, setCurrentRowLimit] = useState<number>(pageRange[1]);
    const eventSelectValue = useRef('');

    type MaintenanceReportFilter = {
        nr?: StingFilterType;
        ele?: StingFilterType;
        event?: StingFilterType;
        text1?: StingFilterType;
        text2?: StingFilterType;
        checkAt?: StingFilterType;
    };

    const [currentState, setState] = useState<MaintenanceReportFilter>({});

    const [clearFilterInputs, setClearFilterInputs] = useState(false);

    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <FilterList
            isDetailsHidden={false}
            onReset={(value: true) =>
            {
                eventSelectValue.current = '';
                setState({});
                setClearFilterInputs(!clearFilterInputs);
            }}

            details={<>
                <IonCol>
                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, text1: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(data, 'text1');
                            return typeheadList;
                        }}
                        text={"Text1"}
                    />
                    <TypeaheadString
                        reset={clearFilterInputs}
                        lines="none"
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, text2: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(data, 'text2');
                            return typeheadList;
                        }}
                        text={"Text2"}
                    />
                </IonCol>
                <IonCol>
                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, checkAt: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(data, 'checkAt');
                            return typeheadList;
                        }}
                        inputType={'date'}
                        text={"Date"}
                    />
                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, nr: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(data, 'nr');
                            return typeheadList;
                        }}
                        text={"No."}
                    />

                </IonCol>
                <IonCol>
                    <TypeaheadString
                        reset={clearFilterInputs}
                        onFilter={async (value: string) =>
                        {
                            if (value.length) setState({ ...currentState, ele: (a: string, b: string): boolean => stringFilter(a, value) });
                            const typeheadList = await getList(data, 'ele');
                            return typeheadList;
                        }}
                        text={"Element"}
                    />
                    <SettingsSelect text={"Event"} value={eventSelectValue.current} onSelect={(value: string) =>
                    {
                        eventSelectValue.current = value;
                        setState({ ...currentState, event: (a: string, b: string): boolean => stringFilter(a, value) });
                        setSearch(search + 1);
                    }}>
                        <SettingsSelectOption text={"fire alarm"} value={"fire alarm"}></SettingsSelectOption>
                        <SettingsSelectOption text={"Active output"} value={"active output"}></SettingsSelectOption>
                        <SettingsSelectOption text={"Tech. messages"} value={"tech. messages"}></SettingsSelectOption>
                        <SettingsSelectOption text={"Interference"} value={"interference"}></SettingsSelectOption>
                        <SettingsSelectOption text={"Test stands"} value={"test stands"}></SettingsSelectOption>
                    </SettingsSelect>
                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
            </>}
        >

        </FilterList >


        <List
            rows={list}
            //maxHeight={mobileView ? undefined : filterDetailsOpen ? '45vh' : "61vh"}
            headers={[
                { title: 'No.', key: 'nr', both: true },
                { title: 'Ele', key: 'ele', both: true },
                { title: 'Event', key: 'event', breakpoint: 1200, up: true },
                { title: 'Text 1', key: 'text1', breakpoint: 1200, up: true },
                { title: 'Text 2', key: 'text2', breakpoint: 1200, up: true },
                { title: 'Check at', key: 'checkAt', breakpoint: 1200, up: true },
            ]}

        />
    </div>;
};

export default MaintenanceReport;