import { CustomerDetails, DateTimeFormat } from "@ews/react-data";
import { useNamedRoutes } from "../../../NamedRoutes";

import
{
    useCustomerDetails,
    useModifyCustomerDetails
} from "../../../ReactData/customer";

import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsLabel from "../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import { LanguageFormatSettingsProps } from "./types";

const LanguageFormatSettings: React.FC<LanguageFormatSettingsProps> = ({ customer, }) =>
{
    const { params } = useNamedRoutes();
    const { id } = params();
    const format = useCustomerDetails(customer.id || id!);

    const modifyCustomerDetails = useModifyCustomerDetails();

    function saveCustomerDetails(key: keyof CustomerDetails, value: any)
    {
        if (!customer.id || !(key in format)) return;
        modifyCustomerDetails(customer.id, { [key]: value } as CustomerDetails);
    }

    return <>
        <SettingsCol>

            <SettingsSelect
                text={"Language setting"}
                value={format.locale || ""}
                onBlur={({ value }) =>
                {
                    if (format.locale === value) return;
                    saveCustomerDetails("locale", value);
                }}>
                <SettingsSelectOption text={"Deutsch"} value={"de"} />
                <SettingsSelectOption text={"English"} value={"en"} />
            </SettingsSelect>
            <SettingsLabel
                text={"Creation date"}
                value={
                    customer.created ? new Date(customer.created).toLocaleString() : ""} />
            <SettingsLabel
                text={"Creation by user"}
                value={""} />
            <SettingsSelect
                disabled
                text={"Created by client"}
                value={""}
                onBlur={({ value }) => { }}>
                <SettingsSelectOption text={"Lst"} value={"lst"} />
                <SettingsSelectOption text={"Admin"} value={"Admin"} />
            </SettingsSelect>

            <SettingsLabel
                text={"Last Modified"}
                value={customer.modified ? new Date(customer.modified).toLocaleString() : ""} />
            <SettingsLabel
                text={"Modified by user"}
                value={""} />
            <SettingsSelect
                disabled
                text={"Modified by client"}
                value={""}
                onBlur={({ value }) => { }}>
                <SettingsSelectOption text={"Lst"} value={"lst"} />
                <SettingsSelectOption text={"Admin"} value={"Admin"} />
            </SettingsSelect>
            <SettingsSelect
                text={"Date/time format"}
                value={format.dateTimeFormat || ""}
                onBlur={({ value }) =>
                {
                    if (format.dateTimeFormat === value) return;
                    saveCustomerDetails("dateTimeFormat", value);
                }}>
                {Object.keys(DateTimeFormat).map((time, index) =>
                {
                    const value = time as keyof typeof DateTimeFormat;
                    return <SettingsSelectOption key={index} text={DateTimeFormat[value]} value={value} />;
                })}

            </SettingsSelect>

        </SettingsCol>
        <SettingsCol>

        </SettingsCol>
        <SettingsCol>

        </SettingsCol>

    </>;
};

export default LanguageFormatSettings;