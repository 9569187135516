import { useL10n } from "@ews/react-localization-context";
import { useModifyUserNotifications, useUser, useUserNotifications } from "../../../ReactData/user";
import { useIsMobile } from "../../../Responsive";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsTableRow from "../../../Settings/SettingsComponents/SettingsTable/SettingsRow/SettingsTableRow";
import SettingsTableColumn from "../../../Settings/SettingsComponents/SettingsTable/SettingsTableColumn/SettingsTableColumn";
import SettingsTableGrid from "../../../Settings/SettingsComponents/SettingsTable/SettingsTableGrid/SettingsTableGrid";
import PushOption from "../PushSettings/Components/Options/Options";
import { EmailSettingsProps } from "./types";
import { IonLabel } from "@ionic/react";

import
{
    UserNotifications
} from '@ews/react-data';

const EmailSettings: React.FC<EmailSettingsProps> = ({ id }) =>
{

    /* const test = Array.from({ length: 100 }, (_, i) => i + 1);

    const user = useUser(id);

    const userData = {
        fireAlarm: {
            begin: true, end: true,
        },
        preAlarm: {
            begin: true, end: true,
        },
        firePreWarning: {
            begin: true, end: true,
        },
        fault: {
            begin: true, end: true,
        },
        technicalMessage: {
            begin: true, end: true,
        },
        disablement: {
            begin: true, end: true,
        },
        extinguishingEvent: {
            begin: true, end: true,
        },
        online: {
            begin: true, end: true,
        },
        activeOutput: {
            begin: true, end: true,
        },
        testAlarm: {
            begin: true,
            end: true,
        },
        newClient: false,
        newUser: false,
        newReseller: false,
        newSystem: false,
        newEnablement: false,
        newGpsArea: false,
        ...user
    }; */

    const user = useUser(id);
    const userNotification = useUserNotifications(id);

    const modifyNotification = useModifyUserNotifications();

    const updateNotification = async (data: UserNotifications) =>
    {
        await modifyNotification(user.id!, data);
    };

    const { translate: t } = useL10n();
    const mobileView = useIsMobile();
    const titlePosition = mobileView ? { textAlign: 'center' } : { textAlign: 'left', verticalAlign: 'sub' };
    // const childPosition = { marginLeft: '20px', fontStyle: 'italic', fontSize: 'medium !important' };

    const sizeMainCol = mobileView ? "12" : "6";
    const sizeSoundCol = "2";
    const otherCols = "1";

    // const EmailDataRef = useRef(userData);

    //const { fireAlarm, preAlarm, firePreWarning, testAlarm, online, fault, technicalMessage, extinguishingEvent, disablement, activeOutput, } = EmailDataRef.current;


    return <>
        <SettingsCol size={sizeMainCol} xl={sizeMainCol} md={sizeMainCol} lg={sizeMainCol}>
            <SettingsTableGrid>
                <SettingsTableRow hidden={mobileView}>
                    <SettingsTableColumn size="4" center={false}>
                        <p style={{ margin: 0 }}>{`${t('[SID_MNU_HK_EVENTS]')}:`}</p>
                    </SettingsTableColumn>
                    <SettingsTableColumn size={otherCols}>
                        <IonLabel style={{ textAlign: "center", margin: 0 }}>{`${t('begin')}:`}</IonLabel>
                    </SettingsTableColumn>
                    <SettingsTableColumn size={otherCols}>
                        <IonLabel style={{ textAlign: "center", margin: 0 }}>{`${t('end')}:`}</IonLabel>
                    </SettingsTableColumn>
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_HK_FIRE_ALARMS]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.fireAlarm || 0}
                        onChange={(fireAlarm) => updateNotification({ fireAlarm } as UserNotifications)}

                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_DEV_STATE_PREALARM]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.preAlarm || 0}
                        onChange={(preAlarm) => updateNotification({ preAlarm } as UserNotifications)}

                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_EV_FIRE_PREALARM_SH]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.fireAlert || 0}
                        onChange={(fireAlert) => updateNotification({ fireAlert } as UserNotifications)}

                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_EV_FIRE_TEST_ALARM_SH]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.testScenario || 0}
                        onChange={(testScenario) => updateNotification({ testScenario } as UserNotifications)}

                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_TAB_ACT_OUTP]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.activeOutput || 0}
                        onChange={(activeOutput) => updateNotification({ activeOutput } as UserNotifications)}

                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_TAB_FAULTS]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.fault || 0}
                        onChange={(fault) => updateNotification({ fault } as UserNotifications)}

                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_EV_TECHN_MESS_ALARM]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.technicalMessage || 0}
                        onChange={(technicalMessage) => updateNotification({ technicalMessage } as UserNotifications)}

                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_EV_TECHN_MESS_SW_OFF_ZONE_SH]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.disablement || 0}
                        onChange={(disablement) => updateNotification({ disablement } as UserNotifications)}

                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_TAB_EXT_EV]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.extinguishingEvent || 0}
                        onChange={(extinguishingEvent) => updateNotification({ extinguishingEvent } as UserNotifications)}

                    />
                </SettingsTableRow>

                <SettingsTableRow>
                    <PushOption
                        style={titlePosition}
                        center={false}
                        label="[SID_MNU_TAB_STATUS]"
                        size={otherCols}
                        options={{
                            email: "begin",
                            email_end: "end",
                        }}
                        value={userNotification.status || 0}
                        onChange={(status) => updateNotification({ status } as UserNotifications)}

                    />
                </SettingsTableRow>



            </SettingsTableGrid>

        </SettingsCol>
        {/* <SettingsCol>
            <SettingsTitle text={"E-mail notifications at"} endText={':'} />
            <SettingsCheckbox text={"New client"} checked={EmailDataRef.current.newClient} onChange={(checked) => { EmailDataRef.current.newClient = checked; }} />
            <SettingsCheckbox text={"New resseler"} checked={EmailDataRef.current.newReseller} onChange={(checked) => { EmailDataRef.current.newReseller = checked; }} />
            <SettingsCheckbox text={"New user"} checked={EmailDataRef.current.newUser} onChange={(checked) => { EmailDataRef.current.newUser = checked; }} />
            <SettingsCheckbox text={"New system"} checked={EmailDataRef.current.newSystem} onChange={(checked) => { EmailDataRef.current.newSystem = checked; }} />
            <SettingsCheckbox text={"New enablement"} checked={EmailDataRef.current.newEnablement} onChange={(checked) => { EmailDataRef.current.newEnablement = checked; }} />
            <SettingsCheckbox text={"New GPS area"} checked={EmailDataRef.current.newGpsArea} onChange={(checked) => { EmailDataRef.current.newGpsArea = checked; }} />

        </SettingsCol> */}
        <SettingsCol>

        </SettingsCol>

    </>;
};

export default EmailSettings;