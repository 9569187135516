import { PaginatedResult, PaginationRequest, SystemTextMessages } from "@ews/react-data";
import { useL10n } from "@ews/react-localization-context";
import { IonButton, IonCol, IonIcon, IonList, IonRow } from "@ionic/react";
import { checkmark, close, pencilOutline, trash } from "ionicons/icons";
import { useEffect, useState } from "react";
import List from "../../../List/List";
import { MenuLink } from "../../../Navigation/Sidebar/Sidebar";
import { useDeleteSystemTextMessages, useFetchSystemTextMessages } from "../../../ReactData/system";
import { Filter } from "../types/reportTypes";
import Modal from "./Modal/Modal";
import style from "./Phonenumbers.module.scss";
import { Data, TextMessagesTypes } from "./types";
import FilterList from "../../../FilterList/FilterList";


type ListFilter = {
    telephoneNumber?: string;
};


const BooleanListItem = (value: boolean) =>
{
    return <IonIcon
        className={style.booleanButton}
        color={value ? "success" : "danger"}
        icon={value ? checkmark : close} />;
};

const TextMessages: React.FC<TextMessagesTypes> = ({ system }) =>
{

    const { translate: t } = useL10n();
    const standardFilter: Filter<ListFilter> = {
        sortCriteria: "telephoneNumber",
        sortOrder: "ASC"
    };

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState<Data | null>(null);

    const [result, setList] = useState<PaginatedResult<SystemTextMessages>>({
        results: [
        ],
        currentPage: 0,
        pageSize: 5,
        totalCountOfEntries: 0,
        totalCountOfPages: 0
    });

    //const { generate } = useNamedRoutes();

    const [currentState, setState] = useState<Filter<any>>(standardFilter);

    const fetch = useFetchSystemTextMessages();
    const deleteTextMessage = useDeleteSystemTextMessages();

    async function getTextMessages(id: string, filter: PaginationRequest & ListFilter)
    {
        const response = await fetch(id, filter);
        if (!response) return;
        setList(response);
    }
    async function _delete(data: SystemTextMessages)
    {
        if (data?.systemId && data?.id) {
            await deleteTextMessage(data.systemId, data.id);
            if (system?.id) {
                getTextMessages(system.id, currentState);
            }
        }
    }
    useEffect(() =>
    {
        if (!system?.id) return;

        getTextMessages(system.id, currentState);

    }, [currentState, system]);

    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>

        <FilterList
            isDetailsHidden={false}
            onReset={(value: true) =>
            {
                setState(standardFilter);
            }}
            details={<IonCol >
                <IonRow class="ion-justify-content-end">
                    <IonButton
                        color={'success'}
                        onClick={() => setIsOpen(true)}
                    >
                        {t('Nummer hinzufügen')}
                    </IonButton>
                </IonRow>
            </IonCol>
            }
        />


        <List
            rows={result.results}
            sort={currentState.sortCriteria ? {
                key: currentState.sortCriteria,
                direction: currentState.sortOrder || "ASC"
            } : undefined}

            pageSize={result.pageSize || 5}
            onSort={(sortCriteria, sortOrder) => setState({ ...currentState, sortCriteria, sortOrder })}
            onPageChange={(page) => setState({ ...currentState, page })}
            onPageSize={(pageSize) => setState({ ...currentState, pageSize })}
            onReload={() => setState({ ...currentState })}
            currentPage={result.currentPage}
            numberOfPages={result.totalCountOfPages}
            //link={{ url: "system:events", paramsKey: "id" }}
            noLink

            onMenu={(id: string, onMenuToggle: () => void) => <IonList>

                <MenuLink
                    title="Edit"
                    icon={pencilOutline}
                    onClick={() =>
                    {
                        const foundedPhoneData = result?.results?.find(data => data.id === id);

                        if (foundedPhoneData) {
                            setCurrentPhoneNumber(foundedPhoneData);
                            setIsOpen(true);
                        };
                        onMenuToggle();
                        return null;
                    }} />
                <MenuLink title="Delete" icon={trash} onClick={() =>
                {
                    const foundedPhoneData = result?.results?.find(data => data.id === id);

                    if (foundedPhoneData) {
                        _delete(foundedPhoneData);
                    };
                    onMenuToggle();
                    return null;
                }} />
            </IonList>}
            headers={[
                { title: 'Telephone Number', key: 'telephoneNumber', both: true },
                { title: 'Alarm', key: 'alarm', both: true, child: (item) => BooleanListItem(item.alarm) },
                { title: 'Pre Alarm', key: 'preAlarm', both: true, child: (item) => BooleanListItem(item.preAlarm) },
                { title: 'Language', key: 'language', both: true },
            ]}
        />
        {/* <ModalButton
            vertical="bottom"
            horizontal="end"
            value={true}
            icon={add}
            color={"success"}
            onClick={() => setIsOpen(true)}
        /> */}
        <Modal
            title={currentPhoneNumber ? "Edit Phone number" : "New Phone number"}
            isOpen={isOpen}
            onUpdate={() =>
            {
                if (system?.id) {
                    getTextMessages(system.id, currentState);
                }
            }}
            onDismiss={() =>
            {
                setCurrentPhoneNumber(null);
                setIsOpen(false);
            }}
            type={currentPhoneNumber ? "edit" : "new"}
            data={currentPhoneNumber || undefined}
            systemId={system.id}
        />
    </div>;
};

export default TextMessages;;